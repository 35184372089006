import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import ProfileInfo from "./ProfileInfo";
import { onglets } from "./Onglets";
import { DeconnexionSvg } from "../../Svgs";
import { closeDrawer } from "../../../REDUX/actions/drawer";

import "./Drawer.css";

const Drawer = () => {
  const location = useLocation();
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    window.location.reload();
  };
  const open = useSelector((state) => state.drawerReducer.open);
  let activeClassName = open ? "active" : "";
  const dispatch = useDispatch();

  return (
    <div
      className={`drawerContainer d-flex justify-content-start flex-column transition02 bg-white ${activeClassName}`}
    >
      <div className="d-lg-none">
        <ProfileInfo />
      </div>
      <div className="logoNavigations d-flex flex-column justify-content-lg-end">
        {onglets.map((el, index) => {
          const activeClass =
            location.pathname === el.to ? "inpage text-white" : "";
          const iconColorClass = location.pathname === el.to ? "white" : null;
          return (
            <Link
              to={el.to}
              className={`drawLink transition02 f16 py-4 ps-2 d-flex my-2 br5 ${activeClass}`}
              key={index}
              onClick={() => {
                dispatch(closeDrawer());
              }}
            >
              <div className="linkContent d-flex justify-content-start align-items-center gap-4 ">
                <div className="iconContainer d-flex justify-content-center align-items-center ms-1">
                  {el.icon(iconColorClass)}
                </div>
                <span className="linkName ms-5 f16 hide">{el.name}</span>
              </div>
            </Link>
          );
        })}
      </div>
      <div
        className="logout mb-2 d-flex align-items-end"
        onClick={handleLogout}
      >
        <div className="drawLink transition02 f16 py-4 ps-2 d-flex my-2 ms-4 br5">
          <div className="d-flex justify-content-start align-items-center gap-4">
            <div className="iconContainer d-flex justify-content-center align-items-center">
              <DeconnexionSvg />
            </div>
            <span className="linkName ms-5 f16 hide">Déconnexion</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drawer;
