import React from "react";
import BreadCrumbHeader from "../Components/Common/BreadCrumbHeader/BreadCrumbHeader";
import ArticleCard from "../Components/Common/CardWrapper/ArticleCard";
import InformationCard from "../Components/Common/InformationCard/informationCard";
import SideBar from "../Components/Common/SideBar/SideBar";
import TabsNavbar from "../Components/Common/TabsNavbar/TabsNavbar";
import ViewHeader from "../Components/Common/ViewHeader/ViewHeader";
import ProfileForm from "../Components/Pages/Profile/ProfileForm";

function ProfileView() {
  return (
    <div>
      <ViewHeader>
        <BreadCrumbHeader title="Mon profil" />
        <TabsNavbar
          tabs={["Informations générales"]}
          activeTab={"Informations générales"}
          withoutGap
          className="d-none d-lg-block"
        />
      </ViewHeader>
      <div className="d-flex justify-content-between">
        <div className="mesComptesMain w78 wm100 py-lg-5">
          <ProfileForm />
        </div>
        <SideBar>
          <InformationCard />
          <ArticleCard />
        </SideBar>
      </div>
    </div>
  );
}

export default ProfileView;
