export const budgetData = [
  {
    id: 1,
    title: "Contrat d'entretien",
    votedBudget: "7 752,00 €",
    consumedAmount: "10",
    toPay: "752,00 €",
  },
  {
    id: 2,
    title: "Syndic",
    votedBudget: "2 035,00 €",
    consumedAmount: "57",
    toPay: "1 154,00 €",
  },
  {
    id: 3,
    title: "Chauffage",
    votedBudget: "1 598,56 €",
    consumedAmount: "95",
    toPay: "1 561,89 €",
  },
];
