import React from "react";

import ProgressBar from "../../../../Common/ProgressBar/ProgressBar";

function AccordionHeader({ title, votedBudget, consumedAmount, toPay }) {
  return (
    <div className="w100 d-flex align-items-center justify-content-between pe-4 pe-lg-0">
      <div className="accordionHeader ms-lg-4 d-flex flex-column flex-lg-row justify-content-between align-items-lg-center w90 wm70">
        <div className="w40 wm100">
          <p className="mb-0 f16 sBold text-darkGrey">{title}</p>
          <p className="mb-0 f16 regular text-darkGrey">
            Budget Voté : <span className="ws-nowrap">{votedBudget}</span>
          </p>
        </div>
        <ProgressBar percentage={consumedAmount} />
        <p className="mb-0 f16 regular text-lg-center text-darkGrey w30 wm100">
          {consumedAmount} %
        </p>
        <p className="mb-0 f16 regular text-center text-darkGrey w25 d-none d-lg-block">
          {toPay}
        </p>
      </div>
      <p className="mb-0 f16  regular text-end text-darkGrey d-block d-lg-none">
        {toPay}
      </p>
    </div>
  );
}

export default AccordionHeader;
