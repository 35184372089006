import "./LinksPopup.css";
const LinksPopup = () => {
  return (
    <div className="linksPopup d-flex flex-column bg-white position-absolute zIndex-100 br5">
      <a
        href="/pdf_doc.pdf"
        target="_blank"
        className="f13 text-dark linkPopup transition02"
      >
        Consulter
      </a>
      <a
        href="/pdf_doc.pdf"
        target="_blank"
        className="f13 text-dark linkPopup transition02"
      >
        Telecharger
      </a>
    </div>
  );
};

export default LinksPopup;
