export const foldersData1 = [
  { id: 1, title: "Décompte de charge", filesCount: 123 },
  { id: 2, title: "Appels de fonds", filesCount: 123 },
  { id: 3, title: "Lorem ipsum", filesCount: 123 },
  { id: 4, title: "Lorem ipsum", filesCount: 123 },
  { id: 5, title: "Lorem ipsum", filesCount: 123 },
  { id: 6, title: "Lorem ipsum", filesCount: 123 },
  { id: 7, title: "Lorem ipsum", filesCount: 123 },
  { id: 8, title: "Lorem ipsum", filesCount: 123 },
  { id: 9, title: "Lorem ipsum", filesCount: 123 },
  { id: 10, title: "Lorem ipsum", filesCount: 123 },
  { id: 11, title: "Lorem ipsum", filesCount: 123 },
  { id: 12, title: "Lorem ipsum", filesCount: 123 },
];
export const foldersData2 = [
  {
    id: 1,
    title: "Annexe au PV Assamblée générale",
    filesCount: 123,
  },
  { id: 2, title: "Assurance immeuble", filesCount: 123 },
  { id: 3, title: "Assurance immeuble", filesCount: 123 },
  { id: 4, title: "Assurance immeuble", filesCount: 123 },
  { id: 5, title: "Assurance immeuble", filesCount: 123 },
  { id: 6, title: "Assurance immeuble", filesCount: 123 },
  { id: 7, title: "Assurance immeuble", filesCount: 123 },
  { id: 8, title: "Assurance immeuble", filesCount: 123 },
  { id: 9, title: "Assurance immeuble", filesCount: 123 },
  { id: 10, title: "Assurance immeuble", filesCount: 123 },
  { id: 11, title: "Assurance immeuble", filesCount: 123 },
  { id: 12, title: "Assurance immeuble", filesCount: 123 },
];
