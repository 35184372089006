export const stateBodyOptions = [
  {
    label: "exemple 1",
    value: "exemple 1",
  },
  {
    label: "example 2",
    value: "example 2",
  },
  {
    label: "example 3",
    value: "example 3",
  },
];
