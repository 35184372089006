import React from "react";

import DocumentsTable from "../DocumentsTable/DocumentsTable";
import FoldersContainer from "../FoldersContainer/FoldersContainer";

import { foldersData2 } from "../../../../helpers/documentsFoldersFakeData";
import { documentsData } from "../../../../helpers/documentsFakeData";

function ImmeubleSection() {
  return (
    <section className="immeubleSection">
      <div className="d-flex justify-content-between mb-4">
        <div>
          <h3 className="text-blue f18">Votre Immeuble Le Nathalia I</h3>
        </div>
      </div>
      <FoldersContainer foldersData={foldersData2} />
      <DocumentsTable documentsData={documentsData} />
    </section>
  );
}

export default ImmeubleSection;
