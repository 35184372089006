import React from "react";
import { useNavigate } from "react-router-dom";
import "./ArticleCard.css";

const ArticleCard = ({ id, title, image, description }) => {
  const navigate = useNavigate();
  const ArticleImg = "https://taboni-api-pprod.labo-linkibe.fr/" + image;
  return (
    <div
      className="col-xl-3 mb-3 pointer"
      onClick={() => navigate(`/articles/${id}`)}
    >
      <img src={ArticleImg} alt="article1" className="br5 w100" />
      <h3 className="f18 text-blue sBold mt-2 mb-1">{title}</h3>
      <p className="f13 regular text-dark">{description || ""}</p>
    </div>
  );
};

export default ArticleCard;
