import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Squash as Hamburger } from "hamburger-react";

import NotificationPanel from "./NotificationPanel/NotificationPanel";
import ProfileLink from "./ProfileLink/ProfileLink";
import CustomInput from "../../Common/CustomInput/CustomInput";
import useResponsive from "../../../hooks/useResponsive";

import { toggleOpen } from "../../../REDUX/actions/drawer";

import { HeaderLogoSvg, SearchSVG } from "../../Svgs";
import user_photo from "../../../Images/Images/photo-user.jpg";
import "./Header.css";

const Header = () => {
  const [searchInput, setSearchInput] = useState("");
  const isMobileSize = useResponsive();
  const isOpen = useSelector((state) => state.drawerReducer.open);
  const user = useSelector((state) => state.usersReducer.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    navigate(`/search?q=${searchInput}`);
  };
  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  return (
    <form
      className="headerContainer bg-blue d-flex justify-content-between align-items-center ps-3 px-lg-3"
      onSubmit={handleSearchSubmit}
    >
      <Link to={"/"} className="pointer">
        <HeaderLogoSvg isMobileSize={isMobileSize} />
      </Link>
      <CustomInput
        placeholder="Rechercher"
        height="52px"
        width="30%"
        className="d-none d-lg-flex"
        search
        callback={handleSearchChange}
      />
      <div className="d-lg-flex d-none align-items-center justify-content-end w25">
        <NotificationPanel />
        <ProfileLink
          profilePic={user_photo}
          fullName={user.Titre}
          description={`${user.Nom} ${user.Prenom}`}
        />
      </div>
      <div className="d-lg-none d-flex align-items-center">
        <div className="d-flex justify-content-center align-items-center me-2">
          <Link to="/search">
            <SearchSVG color="white" width={21} height={21} />
          </Link>
        </div>
        <Hamburger
          toggled={isOpen}
          color="white"
          size={19}
          onToggle={() => dispatch(toggleOpen())}
        />
      </div>
    </form>
  );
};

export default Header;
