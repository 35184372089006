import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import userPhoto from "../../../Images/Images/photo-user.jpg";
import { closeDrawer } from "../../../REDUX/actions/drawer";

function ProfileInfo() {
  const dispatch = useDispatch();
  return (
    <Link to="/profile" onClick={() => dispatch(closeDrawer())}>
      <div className="d-flex flex-column text-darkGrey justify-content-center align-items-center mt-5 mb-4">
        <img
          src={userPhoto}
          alt="profile"
          width="55px"
          height="55px"
          className="br50 mb-3"
        />
        <p className="f16 bold mb-0">TABONI G et M</p>
        <p className="f13 mb-0">c/o cabinet taboni</p>
      </div>
    </Link>
  );
}

export default ProfileInfo;
