import React from "react";

import CustomButton from "../../../Common/CustomButton/CustomButton";
import CustomInput from "../../../Common/CustomInput/CustomInput";
import TableHeader from "../../../Common/TableHeader/TableHeader";

import { contactsData } from "../../../../helpers/signalementsContactsFakeData";
import useResponsive from "../../../../hooks/useResponsive";
import TableRow from "./TableRow";
import "./ContactSection.css";

function ContactSection() {
  const isMobileSize = useResponsive();
  const columnsList = isMobileSize
    ? [{ text: "Nom" }, { text: "Coordonnées" }]
    : [
        { text: "Photo" },
        { text: "Nom" },
        { text: "Fonctions" },
        { text: "E-mail" },
        { text: "Téléphone" },
      ];
  return (
    <section className="contactSection pt-2 ps-lg-4 pt-lg-5">
      <div className="searchContainer d-flex justify-content-between pb-3 pb-lg-5">
        <CustomInput
          placeholder="Nom"
          className="w43 me-3 d-none d-lg-block"
          height="49px"
        />
        <CustomInput
          placeholder="Fonction"
          className="w43 me-3 d-none d-lg-block"
          height="49px"
        />
        <CustomInput
          placeholder="Trouver un contact"
          className="w80 d-block d-lg-none"
          height="49px"
        />
        <CustomButton
          text={isMobileSize ? "" : "Rechercher"}
          prefixIcon="search"
          variant="blue"
          className="searchButton"
        />
      </div>
      <TableHeader
        leftPadding={4}
        rightPadding={isMobileSize ? 2 : null}
        columns={columnsList}
      />
      <div>
        {contactsData.map(({ id, photo, nom, fonction, email, phone }) => (
          <TableRow
            key={id}
            photo={photo}
            nom={nom}
            fonction={fonction}
            email={email}
            phone={phone}
            isMobileSize={isMobileSize}
          />
        ))}
      </div>
    </section>
  );
}

export default ContactSection;
