/* -------------------------------------------------------------------------- */
/*                                dependencies                                */
/* -------------------------------------------------------------------------- */
import React from "react";
// ui components
// assets
import pdfImg from "../../../Images/Icons/pdf-icon.svg";
// style
import "./DocumentDetail.css";
import CustomIconContainer from "../CustomIconContainer/CustomIconContainer";
import { DownloadSVG, ViewSVG } from "../../Svgs";
import MoreDetailsButton from "../MoreDetailsButton/MoreDetailsButton";

/* -------------------------------------------------------------------------- */
/*                                  component                                 */
/* -------------------------------------------------------------------------- */
const DocumentDetail = ({
  title,
  description,
  date,
  titleFontSize,
  className,
  forDashboard = false,
}) => {
  const titleClass = titleFontSize ? `f${titleFontSize}` : "f13";
  const customClassName = className || "";
  const widthClass = forDashboard ? "w100" : "w40";
  return (
    <div
      className={`d-flex align-items-center justify-content-between my-2 position-relative ${customClassName}`}
    >
      <div
        className={`d-flex align-items-center justify-content-start ${widthClass} wm70`}
      >
        <img className="me-3" src={pdfImg} alt="PDF" />
        <p className={`m-0 ${titleClass} text-dark medium f13 medium`}>
          {title}
        </p>
      </div>
      <p className="m-0 f12 text-dark regular w40 d-none d-lg-block">
        {description}
      </p>
      <p className="m-0 f12 text-dark regular w20 d-none d-lg-block">{date}</p>
      <MoreDetailsButton />
      <div className="d-flex d-lg-none">
        <CustomIconContainer variant="white" className="me-2">
          <ViewSVG height={10} width={15} />
        </CustomIconContainer>
        <CustomIconContainer variant="blue">
          <DownloadSVG height={10} width={15} />
        </CustomIconContainer>
      </div>
    </div>
  );
};

export default DocumentDetail;
