import {
  legacy_createStore as createStore,
  compose,
  applyMiddleware,
} from "redux";
import rootReducer from "../reducer/index.js";
import thunk from "redux-thunk";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)));

export default store;
