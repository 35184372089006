import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import {
  resolutionsList,
  resolutionTypesList,
} from "../../../../helpers/assembleVotesFakeData";

import { ViewSVG } from "../../../Svgs";
import { VoteSVG } from "../../../Svgs";
import DocumentsList from "../DocumentsList/DocumentsList";

import "./PropertyVotesSection.css";

function PropertyVotesSection({ title, echeance, date }) {
  const [resolution, setResolution] = useState("Toutes les résolutions");
  return (
    <section className="propertyVotesSection">
      <div className="b-1-lightGrey px-3 px-lg-5 py-4 br6600">
        <h2 className="f25 mb-4 sBold text-blue">{title}</h2>
        <div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center">
          <div>
            <p className="f14 sBold text-blue mb-0">Echéance</p>
            <p className="f14 regular mb-3 mb-lg-0">{echeance}</p>
          </div>
          <div className="mb-3 mb-lg-0">
            <p className="f14 sBold text-blue mb-0">
              Date de l'assemblée générale
            </p>
            <p className="f14 regular mb-0">{date}</p>
          </div>
          <Dropdown
            value={resolution}
            options={resolutionTypesList}
            onChange={(e) => setResolution(e.value)}
            className="resolutionsDropdown"
          />
        </div>
      </div>
      {resolutionsList.map(({ id, title }, index) => {
        const broderRadiusClass =
          index === resolutionsList.length - 1 ? "br0066" : "";
        return (
          <div
            key={id}
            className={`resolutionContainer px-2 py-3 px-lg-5 py-lg-4 d-flex justify-content-between align-items-center b-1-lightGrey border-top-0 ${broderRadiusClass}`}
          >
            <p className="mb-0 f16 fm15 medium me-2 me-lg-0">{title}</p>
            <div className="d-flex">
              <div className="iconContainer view br50 d-flex justify-content-center align-items-center b-1-lightGrey pointer">
                <ViewSVG width="20px" />
              </div>
              <div className="iconContainer vote ms-2 ms-lg-3 br50 d-flex justify-content-center align-items-center b-1-lightGrey pointer bg-blue">
                <VoteSVG color="white" />
              </div>
            </div>
          </div>
        );
      })}
      <DocumentsList className="d-block d-lg-none" />
    </section>
  );
}

export default PropertyVotesSection;
