import React from "react";
import PersonnelsSection from "../PersonnelsSection/PersonnelsSection";
import ImmeubleSection from "../ImmeubleSection/ImmeubleSection";

function TabContentContainer({ activeTab }) {
  if (activeTab === "Personnels") {
    return <PersonnelsSection />;
  } else {
    return <ImmeubleSection />;
  }
}
export default TabContentContainer;
