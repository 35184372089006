import React from "react";
import LoginView from "../../Views/LoginView";
import ViewWrapper from "../../Components/Common/ViewWrapper/ViewWrapper";
import useFetchUser from "../../hooks/useFetchUser";

const AuthCheck = ({ children }) => {
  const { data, loading, error } = useFetchUser();
  return (
    <ViewWrapper loading={loading} error={error}>
      {data.authenticated ? <div>{children}</div> : <LoginView />}
    </ViewWrapper>
  );
};

export default AuthCheck;
