/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// packages :
import React from "react";
// UI components
import BreadCrumbHeader from "../Components/Common/BreadCrumbHeader/BreadCrumbHeader";
import SideBar from "../Components/Common/SideBar/SideBar";
import CommonArticleCard from "../Components/Common/CardWrapper/ArticleCard";
import AgencyDetailsSection from "../Components/Pages/agency/AgencyDetailsSection/AgencyDetailsSection";
import ContactSection from "../Components/Pages/agency/ContactSection/ContactSection";
import ViewHeader from "../Components/Common/ViewHeader/ViewHeader";
// assets

/* -------------------------------------------------------------------------- */
/*                               View components                              */
/* -------------------------------------------------------------------------- */
const MyAgencyView = () => {
  /* ******************************** Rendering ******************************* */
  return (
    <>
      <ViewHeader>
        <BreadCrumbHeader title="Mon agence" />
      </ViewHeader>
      <div className="w100 d-flex justify-content-between mt-lg-5 px-2 ">
        <div className="w100">
          <AgencyDetailsSection />
          <ContactSection />
        </div>
        <SideBar>
          <CommonArticleCard />
        </SideBar>
      </div>
    </>
  );
};

export default MyAgencyView;
