/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// packages :
import React, { useState, useEffect } from "react";
// UI components
import SideBar from "../Components/Common/SideBar/SideBar";
import BreadCrumbHeader from "../Components/Common/BreadCrumbHeader/BreadCrumbHeader";
import InformationCard from "../Components/Common/InformationCard/informationCard";
import ArticleCard from "../Components/Pages/Articles/ArticleCard/ArticleCard";
import ViewHeader from "../Components/Common/ViewHeader/ViewHeader";
import { getArticles } from "../services/ArticlesService";
/* -------------------------------------------------------------------------- */
/*                               View-component                               */
/* -------------------------------------------------------------------------- */
const ArticlesView = () => {
  const [articles, setArticles] = useState([]);
  useEffect(() => {
    getArticles(setArticles);
  }, []);

  return (
    <>
      <ViewHeader>
        <BreadCrumbHeader title="Nos articles" />
      </ViewHeader>
      <div className="w100 d-flex justify-content-between mt-lg-5">
        <div className="w75 wm100">
          <div className="row ms-lg-4 px-2">
            {articles.map((article) => {
              const image =
                article.attributes.banner.data.attributes.image.data.attributes
                  .url;
              const description =
                article.attributes.banner.data.attributes.description;
              const { title } = article.attributes;
              return (
                <ArticleCard
                  key={article.id}
                  id={article.id}
                  title={title}
                  image={image}
                  description={description}
                />
              );
            })}
          </div>
        </div>
        <SideBar>
          <InformationCard />
        </SideBar>
      </div>
    </>
  );
};

export default ArticlesView;
