import React from "react";

import CustomButton from "../../../Common/CustomButton/CustomButton";
import DocumentsTable from "../DocumentsTable/DocumentsTable";
import FoldersContainer from "../FoldersContainer/FoldersContainer";

import { documentsData } from "../../../../helpers/documentsFakeData";
import { foldersData1 } from "../../../../helpers/documentsFoldersFakeData";

function PersonnelsSection() {
  return (
    <section className="personnelsSection">
      <div className="d-flex justify-content-between mb-4">
        <div className="ps-2 ps-lg-0">
          <h3 className="text-blue f18">Votre Immeuble Le Nathalia I</h3>
          <p className="f15">Appartement 7 étage N 0070</p>
        </div>
        <CustomButton
          text="Relevé du compte"
          variant="blue"
          className="d-none d-lg-block"
        />
      </div>
      <FoldersContainer foldersData={foldersData1} />
      <DocumentsTable documentsData={documentsData} />
    </section>
  );
}

export default PersonnelsSection;
