/* -------------------------------------------------------------------------- */
/*                                dependencies                                */
/* -------------------------------------------------------------------------- */
// packages :
import React from "react";
// style
import "./CardWrapper.css";
// UI components
import CardWrapper from "./CardWrapper";
import ContactTag from "../ContactTag/ContactTag";
/* -------------------------------------------------------------------------- */
/*                             common ui component                            */
/* -------------------------------------------------------------------------- */
const ContactCard = ({ title, cardData }) => {
  return (
    <CardWrapper>
      <div className="contactCard position-relative">
        <h3 className="f25 text-blue sBold mb-4">{title}</h3>
        {cardData.map(({ id, image, name, email }, index) => (
          <div key={id}>
            <ContactTag img={image} name={name} email={email} />
            {index < cardData.length - 1 ? <hr /> : null}
          </div>
        ))}
      </div>
    </CardWrapper>
  );
};

export default ContactCard;
