export const userLogin = () => {
  return {
    type: "LOGIN",
  };
};
export const authSuccess = (payload) => {
  return {
    type: "AUTH_SUCCESS",
    payload: payload,
  };
};
export const authError = (payload) => {
  return {
    type: "AUTH_ERROR",
    payload: payload,
  };
};
export const userLogout = () => {
  return {
    type: "LOGOUT",
  };
};
