import React from "react";
import "./CustomIconContainer.css";

function CustomIconContainer({ children, variant, className = "" }) {
  let customClass = "";
  if (variant === "white") {
    customClass = "bg-white b-1-lightGrey";
  } else {
    customClass = "bg-blue b-1-blue";
  }
  return (
    <button
      className={`customIconContainer d-flex justify-content-center align-items-center br50 ${className} ${customClass}`}
    >
      {children}
    </button>
  );
}

export default CustomIconContainer;
