import React from "react";
import BreadCrumbHeader from "../Components/Common/BreadCrumbHeader/BreadCrumbHeader";
import SideBar from "../Components/Common/SideBar/SideBar";
import TabsNavbar from "../Components/Common/TabsNavbar/TabsNavbar";
import PropertyVotesSection from "../Components/Pages/Assemble/PropertyVotesSection/PropertyVotesSection";
import DocumentsList from "../Components/Pages/Assemble/DocumentsList/DocumentsList";
import ViewHeader from "../Components/Common/ViewHeader/ViewHeader";

const AssembleView = () => {
  return (
    <>
      <ViewHeader>
        <BreadCrumbHeader title="Assemblées générales" />
        <TabsNavbar
          activeTab="Vote assemblée générale"
          tabs={["Vote assemblée générale"]}
          withoutGap
          className="d-none d-lg-block"
        />
      </ViewHeader>
      <div className="d-flex justify-content-between">
        <div className="w75 wm100 d-flex flex-column pt-lg-5 px-2 px-lg-0">
          {/* <VoteSection /> */}
          <PropertyVotesSection
            title="Immeuble Le Nathalia I"
            echeance="Samedi le 16 juillet 2022"
            date="Mardi le 19 juillet 2022 à 18h00"
          />
          {/* <VoteForm
            title="Immeuble Le Nathalia I"
            echeance="Samedi le 16 juillet 2022"
            date="Mardi le 19 juillet 2022 à 18h00"
            votesCount={5}
            voteTitle="01 . Nomination du président de l'assemblée génerale"
            description="L'Assemblée Générale désigne en qualité de président de l'Assemblée Générale, Mr(Mme)..."
          /> */}
        </div>
        <SideBar>
          {/* <InformationCard />
          <ArticleCard /> */}
          <DocumentsList filesCount={4} />
        </SideBar>
      </div>
    </>
  );
};

export default AssembleView;
