import React from "react";
import { SearchSVG } from "../../Svgs";

import "./CustomInput.css";
function CustomInput({
  label,
  width,
  height,
  className,
  placeholder,
  callback,
  search,
  type = "text",
  defaultValue = null,
  autoComplete,
  disabled,
}) {
  const customClassName = className || "";
  const searchPadding = search ? "ps-5" : "";
  return (
    <div
      className={`customInputContainer d-flex flex-column position-relative ${customClassName}`}
      style={{ width }}
    >
      {label ? (
        <label className="f15 medium text-dark ps-1 mb-2">{label}</label>
      ) : null}
      {search ? <SearchSVG color="black" width={24} height={24} /> : null}
      <input
        type={type}
        className={`customInput b-1-lightGrey br6 f15 medium p-3 w100 ${searchPadding}`}
        placeholder={placeholder}
        onChange={callback}
        style={{ height: height }}
        defaultValue={defaultValue}
        autoComplete={autoComplete}
        disabled={disabled}
      />
    </div>
  );
}

export default CustomInput;
