import React from "react";
import CustomButton from "../../../Common/CustomButton/CustomButton";

import { BuildingSVG } from "../../../Svgs";

function AccordionHeader({ propertyName, floorNumber, price, index }) {
  return (
    <div className="accordionHeader w100 d-flex flex-column flex-lg-row justify-content-between align-items-start align-items-lg-center pe-4">
      <div className="ps-2 p-lg-0">
        <BuildingSVG />
        <p className="propertyNumber f14 medium text-dark mt-3 mb-1">
          Bien N° {index + 1}: {propertyName}
        </p>
        <p className="floorNumber medium f10 regular text-dark mb-0">
          Etage : {floorNumber}
        </p>
      </div>
      <p className="price f16 regular text-blue mb-lg-0 my-3 my-lg-0">
        {price}€
      </p>
      <div className="headerBtns w20 wm100 d-flex justify-content-lg-end">
        <CustomButton
          text="Extrait"
          variant="blueOutlined"
          className="me-3 mx-lg-2"
        />
        <CustomButton text="Payer" variant="blue" borderColor="blue" />
      </div>
    </div>
  );
}

export default AccordionHeader;
