import { combineReducers } from "redux";
import { usersReducer } from "./users.js";
import { drawerReducer } from "./drawer.js";
import { tabsNavbarReducer } from "./tabsNavbar.js";
import { signalementFormReducer } from "./signalementForm.js";
import { qualitesReducer } from "./qualites.js";
import { immeublesReducer } from "./immeubles.js";

const rootReducer = combineReducers({
  usersReducer,
  drawerReducer,
  tabsNavbarReducer,
  signalementFormReducer,
  qualitesReducer,
  immeublesReducer,
});
export default rootReducer;
