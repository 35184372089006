import React from "react";
import BreadCrumbHeader from "../../Common/BreadCrumbHeader/BreadCrumbHeader";
import CommonArticleCard from "../../Common/CardWrapper/ArticleCard";
import DocumentDetail from "../../Common/DocumentDetail/DocumentDetail";
import InformationCard from "../../Common/InformationCard/informationCard";
import SideBar from "../../Common/SideBar/SideBar";
import ViewHeader from "../../Common/ViewHeader/ViewHeader";

function DesktopVersion() {
  return (
    <>
      <ViewHeader>
        <BreadCrumbHeader title="Résultat de recherche" />
      </ViewHeader>
      <div className="d-flex justify-content-between">
        <div className="w75 wm100 ps-lg-4 mt-lg-4">
          <DocumentDetail
            title="Décompte du 31-10-2021"
            description="Décompte de charge"
            date="31-10-2021"
            className="mb-3"
          />
          <DocumentDetail
            title="Appel du 01-02-2021"
            description="Décompte de charge"
            date="31-10-2021"
            className="mb-3"
          />
          <DocumentDetail
            title="Décompte du 31-10-2021"
            description="Décompte de charge"
            date="31-10-2021"
            className="mb-3"
          />
        </div>
        <SideBar>
          <InformationCard />
          <CommonArticleCard />
        </SideBar>
      </div>
    </>
  );
}

export default DesktopVersion;
