import React from "react";
import Polygone from "../../../../../Images/Images/dashboard/polygone.png";
import Building from "../../../../../Images/Images/dashboard/building.png";
import CustomButton from "../../../../Common/CustomButton/CustomButton";

import "./PropertyCard.css";

const PropertyCard = ({ propertyData }) => {
  const { CODE, NOM_IMM } = propertyData;
  let roles = {
    L: "locataire",
    C: "copropriétaire",
    P: "propriétaire",
  };
  let role = roles[CODE];
  return (
    <section className="propertyCard w100 px-4 py-4 br5 position-relative mb-3 mb-lg-0 mt-lg-4 d-flex flex-column flex-lg-row align-items-center justify-content-between">
      <div className="propertyCardPolygone position-absolute">
        <img src={Polygone} alt="polygone" width="200px" />
      </div>
      <div className="d-flex d-lg-block flex-column align-items-center position-relative">
        <img src={Building} alt="immeuble" width="90px" height="90px" />
        <p className="f15 regular text-white m-0 mt-3 ">
          Vous êtes {role} dans l'immeuble
        </p>
        <h3 className="f25 sBold text-white my-3">{NOM_IMM}</h3>
      </div>
      <div className="align-lg-self-end d-lg-block d-flex flex-column align-items-center ">
        <h4 className="f20 sbold text-white text-end">Vous devez payer</h4>
        <h5 className="f33 regular pe-4 text-yellow text-end position-relative">
          25,07<span className="currencySign f21 position-absolute">€</span>
        </h5>
        <p className="f15 regular text-white text-end">solde {role}</p>
        <div className="d-flex align-items-center justify-content-between">
          <CustomButton
            variant="blue"
            text="Mon extrait"
            className="extraitBtn me-2 bg-transparent"
            width="145px"
            link="/mes-comptes"
          />
          <CustomButton
            variant="blueOutlined"
            text="Payer mon solde"
            borderColor="white"
            width="165px"
            className="payerBtn"
            link="/mes-comptes"
          />
        </div>
      </div>
    </section>
  );
};

export default PropertyCard;
