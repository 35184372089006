export const accordionData = [
  {
    id: 1,
    propertyNumber: "Bien N° 1 : Appartement N 007",
    floorNumber: "Etage : C0",
    price: "25,07",
    documents: [
      {
        id: 1,
        title: "Décompte du 31-10-2021",
        description: "Décompte de charge",
        date: "31-10-2021",
      },
      {
        id: 2,
        title: "Appel du 01-02-2022",
        description: "Décompte de charge",
        date: "31-10-2021",
      },
      {
        id: 3,
        title: "Décompte du 31-10-2021",
        description: "Décompte de charge",
        date: "31-10-2021",
      },
    ],
    extraits: [
      {
        id: 1,
        title: "Extrait mois juillet 2022",
        description: "Trésorerie de l'immeuble",
        date: "31-06-2022",
      },
      {
        id: 2,
        title: "Extrait moi Juin 2022",
        description: "Trésorerie de l'immeuble",
        date: "28-05-2022",
      },
      {
        id: 3,
        title: "Extrait mois Mai 2022",
        description: "Trésorerie de l'immeuble",
        date: "29-4-2022",
      },
    ],
  },
  {
    id: 2,
    propertyNumber: "Bien N°2 : Appartement N 018",
    floorNumber: "Etage : 8",
    price: "00,00",
    documents: [
      {
        id: 1,
        title: "Décompte du 31-10-2021",
        description: "Décompte de charge",
        date: "31-10-2021",
      },
      {
        id: 2,
        title: "Appel du 01-02-2022",
        description: "Décompte de charge",
        date: "31-10-2021",
      },
      {
        id: 3,
        title: "Décompte du 31-10-2021",
        description: "Décompte de charge",
        date: "31-10-2021",
      },
    ],
    extraits: [
      {
        id: 1,
        title: "Extrait mois juillet 2022",
        description: "Trésorerie de l'immeuble",
        date: "31-06-2022",
      },
      {
        id: 2,
        title: "Extrait moi Juin 2022",
        description: "Trésorerie de l'immeuble",
        date: "28-05-2022",
      },
      {
        id: 3,
        title: "Extrait mois Mai 2022",
        description: "Trésorerie de l'immeuble",
        date: "29-4-2022",
      },
    ],
  },
];
