import { useEffect, useState } from "react";

const useResponsive = () => {
  const [isMobileSize, setIsMobileSize] = useState(
    window.innerWidth <= 992 ? true : false
  );
  const handleResponsive = () => {
    if (window.innerWidth <= 992) {
      setIsMobileSize(true);
    } else {
      setIsMobileSize(false);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", handleResponsive);
    return () => window.removeEventListener("resize", handleResponsive);
  }, []);

  return isMobileSize;
};

export default useResponsive;
