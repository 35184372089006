import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { stateBodyOptions } from "../../../../helpers/signalerModalFakeData";
import { InputTextarea } from "primereact/inputtextarea";
import CustomButton from "../../../Common/CustomButton/CustomButton";

import { closeForm } from "../../../../REDUX/actions/signalementForm";
import { useDispatch } from "react-redux";
import "./SignalementNew.css";

function SignalementNew({ onHide = undefined }) {
  const [stateBody, setStateBody] = useState("");
  const dispatch = useDispatch();

  const handleClose = (e) => {
    e.preventDefault();
    dispatch(closeForm(false));
    if (onHide) {
      onHide();
    }
  };

  return (
    <form className="signalementForm mb-5 mb-lg-0">
      <h2 className="formTitle f20 medium text-blue text-center mb-5 mt-4">
        Nouveau Signalement
      </h2>
      <div className="signalementFormContent d-flex flex-column">
        <InputText placeholder="Titre" name="title" />
        <Dropdown
          value={stateBody}
          options={stateBodyOptions}
          onChange={(e) => setStateBody(e.value)}
          placeholder="Corps d'état"
        />
        <InputTextarea rows={5} cols={30} placeholder="Déscription" />
        <label className="custom-file-upload d-flex justify-content-between align-items-center br5 px-3 pointer">
          <input type="file" />
          <span>Ajouter une photo</span>
          <i className="bi bi-camera text-blue f25"></i>
        </label>
        <div className="formFooter d-flex justify-content-start mt-3">
          <CustomButton
            text="Annuler"
            variant="blueOutlined"
            className="me-2"
            callBack={handleClose}
          />
          <CustomButton text="Enregistrer" variant="blue" borderColor="blue" />
        </div>
      </div>
    </form>
  );
}

export default SignalementNew;
