import React from "react";
import DesktopVersion from "../Components/Pages/Search/DesktopVersion";
import MobileVersion from "../Components/Pages/Search/MobileVersion";
import useResponsive from "../hooks/useResponsive";

import "../Components/Pages/Search/Search.css";
import useScrollToTop from "../hooks/useScrollToTop";

function SearchView() {
  useScrollToTop();
  const isMobileSize = useResponsive();
  return (
    <section className="searchView">
      {isMobileSize ? <MobileVersion /> : <DesktopVersion />}
    </section>
  );
}

export default SearchView;
