import React from "react";
import { EnCoursSVG, TraiteSVG } from "../../../Svgs";

function AccordionHeader({ date, signalement, statut }) {
  const extractDate = (d) => {
    return `${d.substring(6)}/${d.substring(4, 6)}/${d.substring(0, 4)}`;
  };
  const { type, description } = signalement;
  return (
    <div
      className={`accordionHeader d-flex flex-column flex-lg-row justify-content-between align-items-center w75 ${statut}`}
    >
      <p className="mb-0 text-start f12 medium w30 text-dark wm100">
        {extractDate(date)}
      </p>
      <div className="w50 d-flex flex-column align-items-start wm100 my-3 my-lg-0">
        <p className="mb-0 f14 regular text-dark">{type}</p>
        <p className="mb-0 f15 sBold text-dark w90">{description}</p>
      </div>
      <div className="w20 d-flex justify-content-start wm100">
        {statut === "inProgress" ? (
          <div className="d-flex align-items-center ">
            <EnCoursSVG color="#2c2c2c" />
            <p className="f14 regular ms-3 mb-0 text-dark">En Cours</p>
          </div>
        ) : (
          <div className="d-flex align-items-center">
            <TraiteSVG color="#2c2c2c" />
            <p className="f14 regular ms-3 mb-0 text-dark">Traité</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default AccordionHeader;
