import React from "react";
import NavigateProfileCard from "./NavigateProfileCard/NavigateProfileCard";
import NewsAgencyCard from "./NewsAgencyCard/NewsAgencyCard";
import useResponsive from "../../../../hooks/useResponsive";

import "./IntroSection.css";
import PropertyCardsSection from "../PropertyCardsSection/PropertyCardsSection";
const IntroSection = ({ user, propertiesData }) => {
  const isMobileSize = useResponsive();
  return (
    <div className="dashboardIntroSection d-flex flex-column flex-lg-row align-items-center justify-content-between ">
      <NavigateProfileCard user={user} />
      {isMobileSize ? (
        <PropertyCardsSection propertiesData={propertiesData} />
      ) : (
        <NewsAgencyCard hasFullHeight={false} />
      )}
    </div>
  );
};

export default IntroSection;
