export const servicesOptions = [
  {
    label: "Changer de syndic",
    value: "Changer de syndic",
  },
  {
    label: "Transaction",
    value: "Transaction",
  },
  {
    label: "Location/gérance",
    value: "Location/gérance",
  },
];
