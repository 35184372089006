import React from "react";
import { useDispatch } from "react-redux";
import { changeTab } from "../../../REDUX/actions/tabsNavbar";

import "./TabsNavbar.css";

function TabsNavbar({
  activeTab,
  setActiveTab,
  tabs,
  width = "w100",
  withoutGap,
  tabMarginRight = "me-5",
  className = "",
  sticky,
}) {
  const dispatch = useDispatch();
  const gapClassName = withoutGap ? "" : "ps-4";
  const stickyClassName = sticky ? "position-sticky" : "";
  return (
    <div
      className={`tabsNavbar pb-2 d-flex ${width} ${gapClassName} ${stickyClassName} ${className}`}
    >
      {tabs.map((tabName) => {
        const activeClassName = activeTab === tabName ? "active" : "";
        return (
          <button
            key={tabName}
            className={`f15 text-dark medium bg-white border-0 mb-0 position-relative ${tabMarginRight} ${activeClassName}`}
            onClick={() => {
              setActiveTab(tabName);
              dispatch(changeTab({ payload: tabName }));
            }}
          >
            {tabName}
          </button>
        );
      })}
    </div>
  );
}

export default TabsNavbar;
