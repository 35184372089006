const initialState = {
  showForm: false,
};

export const signalementFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_FORM":
      return { showForm: true };
    case "CLOSE_FORM":
      return { showForm: false };
    default:
      return state;
  }
};
