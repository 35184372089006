export const extraitData = [
  {
    id: 1,
    attributes: {
      DATE: "01/11/2021",
      LIBELLE: "Appel de fond du 01/11/2021 au 31/01/2022",
      DEBIT: "18,37",
      CREDIT: "-",
      SOLDE: "27,77",
    },
  },
  {
    id: 2,
    attributes: {
      DATE: "01/11/2021",
      LIBELLE: "Fond de prévoyance Alur",
      DEBIT: "1,31",
      CREDIT: "-",
      SOLDE: "29,08",
    },
  },
  {
    id: 3,
    attributes: {
      DATE: "30/11/2021",
      LIBELLE: "Votre virement",
      DEBIT: "-",
      CREDIT: "19,67",
      SOLDE: "9,41",
    },
  },
  {
    id: 4,
    attributes: {
      DATE: "01/02/2022",
      LIBELLE: "Appel au fond du 01/02/2022 au 31/04/2022",
      DEBIT: "18,36",
      CREDIT: "-",
      SOLDE: "27,77",
    },
  },
  {
    id: 5,
    attributes: {
      DATE: "01/02/2022",
      LIBELLE: "Solde de charge",
      DEBIT: "-",
      CREDIT: "3,35",
      SOLDE: "25,07",
    },
  },
];
