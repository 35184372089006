import React from "react";
import "./NavigateProfileCard.css";
import CustomButton from "../../../../Common/CustomButton/CustomButton";
import Polygone from "../../../../../Images/Images/dashboard/polygone.png";
import Person from "../../../../../Images/Images/dashboard/person.png";
const NavigateProfileCard = ({ user }) => {
  const { Nom, Prenom, Titre, Adr1, Adr2, CP, Ville, email } = user;
  return (
    <div className="navigateProfileCard w43 wm100 br5 bg-yaleBlue px-4 pt-5 pb-3 position-relative h100 mb-3 mb-lg-0">
      <div className="position-absolute profileCardPolygoneAsset">
        <img src={Polygone} alt="shape" width="136px" height="142px" />
      </div>
      <h2 className="f25 text-yellow sBold mb-3 position-relative">
        Bienvenue
      </h2>
      <h3 className="f15 bold text-white position-relative">
        {Titre} {Nom} {Prenom}
      </h3>
      <p className="f15 regular text-white m-0 position-relative">
        {Adr1}
        <br />
        {Adr2} {CP} {Ville}
      </p>
      <p className="f15 regular text-white mt-4 position-relative">{email}</p>
      <CustomButton
        variant="blueOutlined"
        text="Gérer mon profil"
        width="165px"
        link="/profile"
        borderColor="white"
        className="transparentHover"
      />
      <div className="position-absolute profileCardPersonAsset">
        <img src={Person} alt="user" width="144px" height="173px" />
      </div>
    </div>
  );
};

export default NavigateProfileCard;
