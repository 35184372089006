import "./NotificationItem.css";

const NotificationItem = () => {
  return (
    <div className="notificationItem py-3">
      <div className="d-flex align-items-center justify-content-between mb-1">
        <h5 className="text-dark f14 sBold m-0">Notification</h5>
        <span className="text-spanishGray f10 m-0">
          11/07/2022&nbsp; &nbsp;16h00
        </span>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="me-3">
          <div className="notificationItemIcon text-white f14 sBold bg-blue d-flex align-items-center justify-content-center ">
            N
          </div>
        </div>
        <p className="f13 text-dark regular m-0">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit
        </p>
      </div>
    </div>
  );
};

export default NotificationItem;
