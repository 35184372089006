import React from "react";
import { AccordionTab } from "primereact/accordion";

import Accordion from "../../../../Common/Accordion/Accordion";
import AccordionHeader from "./AccordionHeader";

import { budgetData } from "../../../../../helpers/budgetAccordionFakeData";
import "./AccordionContainer.css";
import ExtraitTable from "../../ExtraitSection/ExtraitTable";
import { extraitData } from "../../../../../helpers/mesComptesExtraitFakeData";

function AccordionContainer() {
  return (
    <div className="mt-5 mx-lg-5">
      <Accordion>
        {budgetData.map(({ id, title, votedBudget, consumedAmount, toPay }) => (
          <AccordionTab
            key={id}
            headerTemplate={
              <AccordionHeader
                title={title}
                votedBudget={votedBudget}
                consumedAmount={consumedAmount}
                toPay={toPay}
              />
            }
          >
            <ExtraitTable tableData={extraitData} />
          </AccordionTab>
        ))}
      </Accordion>
    </div>
  );
}

export default AccordionContainer;
