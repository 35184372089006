export const searchData = [
  { id: 1, tagName: "Biens" },
  { id: 2, tagName: "Décompte, extrait" },
  { id: 3, tagName: "Syndic" },
  { id: 4, tagName: "Taboni" },
  { id: 5, tagName: "Signalements" },
  { id: 6, tagName: "Documents" },
  { id: 7, tagName: "Comptes" },
  { id: 8, tagName: "Assemblées générales" },
  { id: 9, tagName: "PV" },
  { id: 10, tagName: "Payer" },
  { id: 11, tagName: "Contact" },
];
