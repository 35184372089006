import React, { useState } from "react";
import CustomInput from "../../Common/CustomInput/CustomInput";
import CustomButton from "../../Common/CustomButton/CustomButton";

import SearchTag from "./SearchTag";
import { searchData } from "../../../helpers/searchFakeData";

function MobileVersion() {
  const [filter, setFilter] = useState("Biens");
  return (
    <div className="mobileVersion px-2">
      <div className="d-flex justify-content-between align-items-center">
        <CustomInput
          placeholder="Rechercher"
          height="47px"
          className="searchInput me-2"
          search
        />
        <CustomButton text="Annuler" variant="blue" />
      </div>
      <div className="mt-5">
        {searchData.map(({ id, tagName }) => (
          <SearchTag
            key={id}
            tagName={tagName}
            filter={filter}
            setFilter={setFilter}
          />
        ))}
      </div>
    </div>
  );
}

export default MobileVersion;
