import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import "./LoadingView.css";

function LoadingView() {
  return (
    <div className="loadingView d-flex align-items-center">
      <ProgressSpinner
        style={{
          display: "flex",
          alignItems: "center",
          width: "100px",
          height: "100px",
        }}
        strokeWidth={3}
        className="loadingViewSpinner"
      />
    </div>
  );
}

export default LoadingView;
