/* -------------------------------------------------------------------------- */
/*                                dependencies                                */
/* -------------------------------------------------------------------------- */
// packages
import React from "react";
// assets
import AgenceImg from "../../../../Images/Images/mon-agence/agence.png";
/* -------------------------------------------------------------------------- */
/*                               page component                               */
/* -------------------------------------------------------------------------- */
const AgencyDetailsSection = () => {
  /* ******************************** Rendering ******************************* */
  return (
    <div className="w100">
      <div className="d-flex flex-column flex-lg-row w90 wm100 ms-lg-5">
        <img src={AgenceImg} alt="agence" className="w45 wm100 mb-4 mb-lg-0" />
        <div className="ms-lg-5 my-auto">
          <h3 className="f17 sBold text-blue m-0 text-uppercase">
            cabinet Taboni
          </h3>
          <p className="f14 text-dark regular text-uppercase">
            82 BD GAMBETTA <br /> 06000 NICE
          </p>
          <h3 className="f14 sBold text-blue m-0 text-uppercase">téléphone</h3>
          <p className="f14 text-dark regular text-uppercase">04 93 88 84 14</p>
          <h3 className="f14 sBold text-blue m-0 text-uppercase">
            HORAIRES D'OUVERTURE
          </h3>
          <h4 className="f14 text-dark sBold m-0">Lundi au jeudi</h4>
          <p className="f14 text-dark regular text-uppercase my-0">
            de 9h à 12 et 14h à 18h
          </p>
          <h4 className="f14 text-dark sBold m-0">Vendredi</h4>
          <p className="f14 text-dark regular text-uppercase my-0">
            de 9h à 12 et 14h à 17h
          </p>
        </div>
      </div>
    </div>
  );
};

export default AgencyDetailsSection;
