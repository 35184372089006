import React from "react";
import { Accordion as PrimeAccordion } from "primereact/accordion";

import "./Accordion.css";

function Accordion({ children }) {
  return (
    <PrimeAccordion
      expandIcon={<i className="bi bi-chevron-down"></i>}
      collapseIcon={<i className="bi bi-chevron-up"></i>}
    >
      {children}
    </PrimeAccordion>
  );
}

export default Accordion;
