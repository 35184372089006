export const notificationsData = [
  {
    id: 1,
    title: "Notification 1",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    date: "11/07/2022 16h00",
  },
  {
    id: 2,
    title: "Notification 2",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    date: "11/07/2022 16h00",
  },
  {
    id: 3,
    title: "Notification 3",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    date: "11/07/2022 16h00",
  },
  {
    id: 4,
    title: "Notification 4",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    date: "11/07/2022 16h00",
  },
];
