import {
  AssembleeSvg,
  BienSvg,
  ComptesSvg,
  DashboardSvg,
  DocumentsSvg,
  SignalementsSvg,
} from "../../Svgs";

export const onglets = [
  {
    name: "Tableau de bord",
    icon: (color) => <DashboardSvg color={color} />,
    to: "/",
    showOnlyToAdmin: false,
  },

  {
    name: "Biens",
    icon: (color) => <BienSvg color={color} />,
    to: "/bien",
    showOnlyToAdmin: false,
  },
  {
    name: "Signalements",
    icon: (color) => <SignalementsSvg color={color} />,
    to: "/signalements",
    showOnlyToAdmin: false,
  },
  {
    name: "Mes Comptes",
    icon: (color) => <ComptesSvg color={color} />,
    to: "/mes-comptes",
    showOnlyToAdmin: false,
  },

  {
    name: "Documents",
    icon: (color) => <DocumentsSvg color={color} />,
    to: "/documents",
    showOnlyToAdmin: false,
  },
  {
    name: "Assemblées générales",
    icon: (color) => <AssembleeSvg color={color} />,
    to: "/assemblee",
    showOnlyToAdmin: false,
  },
];

export const mobileOnglets = [
  {
    name: "Comptes",
    icon: (color) => <ComptesSvg color={color} />,
    to: "/mes-comptes",
    showOnlyToAdmin: false,
  },
  {
    name: "Documents",
    icon: (color) => <DocumentsSvg color={color} />,
    to: "/documents",
    showOnlyToAdmin: false,
  },
  {
    name: "Signalements",
    icon: (color) => <SignalementsSvg color={color} />,
    to: "/signalements",
    showOnlyToAdmin: false,
  },
  // {
  //   name: "Contact",
  //   icon: (color) => <ContactSVG color={color} />,
  //   showOnlyToAdmin: false,
  // },
];
