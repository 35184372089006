import React, { useState } from "react";

import BreadCrumbHeader from "../Components/Common/BreadCrumbHeader/BreadCrumbHeader";
import InformationCard from "../Components/Common/InformationCard/informationCard";
import SideBar from "../Components/Common/SideBar/SideBar";
import TabsNavbar from "../Components/Common/TabsNavbar/TabsNavbar";
import ViewHeader from "../Components/Common/ViewHeader/ViewHeader";

import TabContentContainer from "../Components/Pages/Documents/TabContentContainer/TabContentContainer";
import useScrollToTop from "../hooks/useScrollToTop";
import "../Components/Pages/Documents/Documents.css";

const DocumentsView = () => {
  useScrollToTop();
  const [activeTab, setActiveTab] = useState("Personnels");

  return (
    <div className="documentsView">
      <ViewHeader>
        <BreadCrumbHeader title="Documents" subtitle={activeTab} />
        <TabsNavbar
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={["Personnels", "Immeuble"]}
          className="wm100"
        />
      </ViewHeader>
      <div className="d-flex justify-content-between">
        <div className="mesComptesMain w75 wm100 ps-2 ps-lg-4 py-4 py-lg-5">
          <TabContentContainer activeTab={activeTab} />
        </div>
        <SideBar>
          <InformationCard />
          {/* <ContactCard title="Comptables" cardData={comptablesData} /> */}
        </SideBar>
      </div>
    </div>
  );
};

export default DocumentsView;
