import React from "react";

import TableHeader from "../../../Common/TableHeader/TableHeader";
import TableRow from "./TableRow";

import useResponsive from "../../../../hooks/useResponsive";

function ExtraitTable({ tableData }) {
  const isMobileSize = useResponsive();
  const columnsList = isMobileSize
    ? [
        { text: "Libellé", width: 40, padding: "0px 0px 0px 1rem" },
        {
          text: "Dépenses",
          width: 20,
          alignment: "center",
          padding: "0px 0px 0px 2.5%",
        },
        { text: "Recettes", width: 15, alignment: "center" },
        { text: "Solde", width: 15, alignment: "center" },
      ]
    : [
        { text: "Date", width: 15, padding: "0px 0px 0px 1rem" },
        { text: "Libellé", width: 40, padding: "0px 0px 0px 1rem" },
        { text: "Dépenses", width: 15, alignment: "center" },
        { text: "Recettes", width: 15, alignment: "center" },
        { text: "Solde", width: 15, alignment: "center" },
      ];
  const soldeClassName = isMobileSize ? "text-blue" : "text-shadowBlue";
  const montantClassName = isMobileSize ? "text-blue" : "text-dark";
  return (
    <div className="w100">
      <TableHeader
        leftPadding={isMobileSize ? "0" : "4"}
        rightPadding={isMobileSize ? "0" : "2"}
        columns={columnsList}
      />
      <div className="extraitTable">
        {tableData.map((tableRowData) => {
          const { DATE, LIBELLE, DEBIT, CREDIT, SOLDE } =
            tableRowData.attributes;
          return (
            <TableRow
              key={tableRowData.id}
              date={DATE}
              libelle={LIBELLE}
              depense={DEBIT}
              recettes={CREDIT}
              solde={SOLDE}
              isMobileSize={isMobileSize}
            />
          );
        })}
        <div className="d-flex justify-content-between p-4 pe-2 br0066 b-1-lightGrey border-top-0 f15 medium text-center">
          <p className={`mb-0 ms-lg-5 w30 wm-auto ${soldeClassName}`}>
            Solde à payer
          </p>
          <p className={`mb-0 w15 wm-auto ${montantClassName}`}>25,07 €</p>
        </div>
      </div>
    </div>
  );
}

export default ExtraitTable;
