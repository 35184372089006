import pierre from "../Images/Images/pierre.jpg";
import corinne from "../Images/Images/corinne.jpg";

export const contactsData = [
  { id: 1, image: pierre, name: "Pierre TABONI", email: "intgm@mail.fr" },
  { id: 2, image: corinne, name: "Corinne TABONI", email: "intgm@mail.fr" },
];

export const agenceContactList = [
  {
    depName: "Direction",
    directionList: [
      { id: 1, image: pierre, name: "Pierre TABONI", email: "intgm@mail.fr" },
      { id: 2, image: corinne, name: "Corinne TABONI", email: "intgm@mail.fr" },
    ],
  },
  {
    depName: "Assistantes",
    directionList: [
      { id: 1, image: pierre, name: "Pierre TABONI", email: "intgm@mail.fr" },
      { id: 2, image: corinne, name: "Corinne TABONI", email: "intgm@mail.fr" },
      { id: 3, image: corinne, name: "Corinne TABONI", email: "intgm@mail.fr" },
    ],
  },
  {
    depName: "Comptabilité",
    directionList: [
      { id: 1, image: pierre, name: "Pierre TABONI", email: "intgm@mail.fr" },
      { id: 2, image: corinne, name: "Corinne TABONI", email: "intgm@mail.fr" },
      { id: 3, image: corinne, name: "Corinne TABONI", email: "intgm@mail.fr" },
    ],
  },
];
