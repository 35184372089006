import React from "react";
import { Link } from "react-router-dom";

import CustomButtonCore from "./CustomButtonCore";
import "./CustomButton.css";

function CustomButton(props) {
  if (props.link) {
    return (
      <Link to={props.link} className="linkContainer">
        <CustomButtonCore {...props} />
      </Link>
    );
  } else {
    return <CustomButtonCore {...props} />;
  }
}

export default CustomButton;
