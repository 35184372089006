import React, { useState } from "react";
import NotificationWrapper from "../../../Common/NotificationWrapper/NotificationWrapper";
import { NotificationSVG } from "../../../Svgs";

import "./NotificationPanel.css";

function NotificationPanel({ forMobile }) {
  const [show, setShow] = useState(false);
  const marginClass = forMobile ? "" : "me-5";
  const directionClass = forMobile ? "upward" : "";

  return (
    <div
      className={`notificationPanel position-relative ${marginClass} ${directionClass}`}
    >
      {forMobile ? (
        <div
          onClick={() => setShow(!show)}
          className="d-flex flex-column align-items-center justify-content-center"
        >
          <NotificationSVG color="black" />
          <p className="f12 mb-0 sBold linkTitle">Notification</p>
        </div>
      ) : (
        <div
          onClick={() => setShow(!show)}
          className="iconContainer d-flex align-items-center justify-content-center br50 pointer"
        >
          <NotificationSVG color="white" />
        </div>
      )}
      <NotificationWrapper show={show} forMobile={forMobile} />
    </div>
  );
}

export default NotificationPanel;
