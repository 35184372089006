import React from "react";
import { Routes as Switch, Route } from "react-router-dom";
import AssembleView from "../Views/AssembleView";
import BienView from "../Views/BienView";
import DashboardView from "../Views/DashboardView";
import DocumentsView from "../Views/DocumentsView";
import MesComptesView from "../Views/MesComptesView";
import ProfileView from "../Views/ProfileView";
import SignalementsView from "../Views/SignalementsView";
import ArticlesView from "../Views/ArticlesView";
import ArticleView from "../Views/ArticleView";
import MyAgencyView from "../Views/MyAgencyView";
import ContactView from "../Views/ContactView";
import SearchView from "../Views/SearchView";
import RedirectView from "../Components/Common/RedirectView/RedirectView";

const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact element={<DashboardView />}></Route>
      <Route path="/bien" exact element={<BienView />}></Route>
      <Route path="/signalements" exact element={<SignalementsView />}></Route>
      <Route path="/mes-comptes" exact element={<MesComptesView />}></Route>
      <Route path="/documents" exact element={<DocumentsView />}></Route>
      <Route path="/assemblee" exact element={<AssembleView />}></Route>
      <Route path="/profile" exact element={<ProfileView />}></Route>
      <Route path="/articles" exact element={<ArticlesView />}></Route>
      <Route path="/articles/:id" exact element={<ArticleView />}></Route>
      <Route path="/mon-agence" exact element={<MyAgencyView />}></Route>
      <Route path="/contact" exact element={<ContactView />}></Route>
      <Route path="/search" exact element={<SearchView />}></Route>
      <Route path="*" exact element={<RedirectView />}></Route>
    </Switch>
  );
};

export default Routes;
