import React from "react";
import MoreDetailsButton from "../../../../../Common/MoreDetailsButton/MoreDetailsButton";

const ReportItem = ({ eventData }) => {
  const { obj1, obj2, obj3, obj4, type, traite } = eventData.attributes;
  const status = traite === "O" ? "Traité" : "En Cours";
  return (
    <div className="py-3 b-bot-1-lightGrey position-relative">
      <h6 className="f14 regular text-dark">{type}</h6>
      <div className="d-flex align-items-center justify-content-between">
        <h5 className="f15 sBold text-dark reportTitle w70 wm50 mb-lg-0">
          {obj1} {obj2} {obj3} {obj4}
        </h5>
        <p className="m-0 f14 regular text-dark">{status}</p>
        <MoreDetailsButton />
      </div>
    </div>
  );
};

export default ReportItem;
