/* -------------------------------------------------------------------------- */
/*                                dependencies                                */
/* -------------------------------------------------------------------------- */
// UI components
import CardWrapper from "./CardWrapper";
import Separator from "../../Common/Separator/Separator";
// assets
import articleImg from "../../../Images/Images/photo-article.jpg";
// style
import "./CardWrapper.css";
import { Link } from "react-router-dom";
/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */
const CommonArticleCard = () => {
  /* ******************************** Rendering ******************************* */
  return (
    <CardWrapper>
      <div className="articleCardCommon card-wrapper-article-card mt-3">
        <h3 className="f25 text-blue sBold mb-3">Nos articles</h3>
        <img src={articleImg} alt="article" className="w100 mb-3 br5" />
        <h4 className="f18 text-blue sBold">Lorem ipsum dolor</h4>
        <p className="f13">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <Separator width="107.5%" />
        <Link to="/articles" className="f15 text-blue medium">
          Voir tous les articles
        </Link>
      </div>
    </CardWrapper>
  );
};

export default CommonArticleCard;
