/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// packages
import React from "react";
// helpers and fake data:
import { agenceContactList } from "../../../../helpers/contactCardFakeData";
// UI components
import ContactTag from "../../../Common/ContactTag/ContactTag";
/* -------------------------------------------------------------------------- */
/*                                 components                                 */
/* -------------------------------------------------------------------------- */
const ContactSection = () => {
  /* ******************************** rendering ******************************* */
  return (
    <div className="ms-lg-5 my-5">
      <h2 className="text-blue sBold f22">Mes contacts</h2>
      <div className="d-flex flex-column align-items-start justify-content-between">
        {agenceContactList.map(({ depName, directionList }) => (
          <div className="mt-33">
            <h3 className="f15 text-blue medium">{depName}</h3>
            <div className="d-flex align-items-center flex-wrap gap-lg-5 gap-3 mt-35">
              {directionList.map(({ id, image, name, email }) => (
                <ContactTag key={id} img={image} name={name} email={email} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactSection;
