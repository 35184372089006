import React from "react";
import { AssistanceSVG } from "../../../Svgs";

import "./AssistanceButton.css";

function AssistanceButton({ setOpen }) {
  return (
    <button
      className="assistanceButton f15 px-3 br5 bg-blue text-white transition02"
      onClick={() => setOpen(true)}
    >
      <AssistanceSVG />
      <span className="ps-2">Assistance</span>
    </button>
  );
}

export default AssistanceButton;
