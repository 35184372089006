import React from "react";

function TableHeader({ columns, leftPadding, rightPadding, className = "" }) {
  const leftPaddingClass = leftPadding ? `ps-${leftPadding}` : "ps-5";
  const rightPaddingClass = rightPadding ? `pe-${rightPadding}` : "pe-5";
  return (
    <div
      className={`tableHeader d-flex align-items-center justify-content-between b-1-lightGrey br6600 py-4 sBold ${leftPaddingClass} ${rightPaddingClass} ${className}`}
    >
      {columns.map(({ text, width, alignment, padding }, index) => {
        const widthStyle = width ? `${width}%` : "";
        const alignmentStyle = alignment ? `text-${alignment}` : "";
        return (
          <p
            key={index}
            className={`mb-0 pointer f12 medium ${alignmentStyle} `}
            style={{ width: widthStyle, padding: padding }}
          >
            {text}
          </p>
        );
      })}
    </div>
  );
}

export default TableHeader;
