import React from "react";
import { useSelector } from "react-redux";

import BreadCrumbHeader from "../Components/Common/BreadCrumbHeader/BreadCrumbHeader";
import CommonArticleCard from "../Components/Common/CardWrapper/ArticleCard";
import InformationCard from "../Components/Common/InformationCard/informationCard";
import ViewHeader from "../Components/Common/ViewHeader/ViewHeader";
import SideBar from "../Components/Common/SideBar/SideBar";
import ViewWrapper from "../Components/Common/ViewWrapper/ViewWrapper";

import AccordionSection from "../Components/Pages/Bien/AccordionSection/AccordionSection";
import useFetchBiens from "../hooks/useFetchBiens";

const BienView = () => {
  const user = useSelector((state) => state.usersReducer.user);
  const { data, loading, error } = useFetchBiens(user.ID_PERSO);

  return (
    <ViewWrapper loading={loading} error={error}>
      <div className="d-flex flex-column">
        <ViewHeader>
          <BreadCrumbHeader title="Biens" />
        </ViewHeader>
        <div className="d-flex justify-content-between">
          <div className="w75 wm100">
            <AccordionSection propertiesData={data} />
          </div>
          <SideBar>
            <InformationCard />
            <CommonArticleCard />
          </SideBar>
        </div>
      </div>
    </ViewWrapper>
  );
};

export default BienView;
