import "./App.css";
import "./custom.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "react-circular-progressbar/dist/styles.css";

import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes/Routes";
import ErrorBoundary from "./Utils/ErrorBoundary/ErrorBoundary";
import AuthCheck from "./Components/AuthCheck/AuthCheck";
import Layout from "./Components/Layout/Layout";

function App() {
  return (
    <div className="App">
      <ErrorBoundary>
        <BrowserRouter>
          <AuthCheck>
            <Layout>
              <Routes />
            </Layout>
          </AuthCheck>
        </BrowserRouter>
      </ErrorBoundary>
    </div>
  );
}

export default App;
