export const toggleOpen = () => {
  return {
    type: "TOGGLE_OPEN",
  };
};

export const closeDrawer = () => {
  return {
    type: "CLOSE_DRAWER",
  };
};
