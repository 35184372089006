import React from "react";
import useResponsive from "../../../hooks/useResponsive";
import { FolderSVG, DetailsSvg } from "../../Svgs";

import "./FolderCard.css";
function FolderCard({ title, filesCount }) {
  const isMobileSize = useResponsive();
  const maxLength = isMobileSize ? 56 : 27;
  const formatedTitle =
    title.length > maxLength ? `${title.substring(0, 24)}...` : title;
  const topMarginClass = title.length > 24 ? "mt-0" : "mt-3";
  return (
    <div className="folderCard position-relative d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between ps-3 pt-2 p-lg-2 pb-0 me-3 me-lg-1 mb-1 br10 transition02 pointer">
      <div className="iconContainer d-flex justify-content-center align-items-center p-1 mt-2 bg-lightGrey align-self-start">
        <FolderSVG color="#2c659d" />
      </div>
      <div className={`w60 wm100 mt-lg-0 ${topMarginClass}`}>
        <p className="cardTitle f14 text-darkGrey medium mb-1 me-2">
          {formatedTitle}
        </p>
        <p className="f10 text-mediumGrey mb-0">{filesCount} fichiers</p>
      </div>
      <div className="plusIconContainer align-self-start mt-1">
        <DetailsSvg color="#999999" />
      </div>
    </div>
  );
}

export default FolderCard;
