import React from "react";
import "./NewsAgencyCard.css";
import CardWrapper from "../../../../Common/CardWrapper/CardWrapper";
import CustomButton from "../../../../Common/CustomButton/CustomButton";
const NewsAgencyCard = ({ hasFullHeight }) => {
  return (
    <CardWrapper width={55} hasFullHeight={hasFullHeight}>
      <div className="br5 px-2 px-lg-4 pt-lg-4 ">
        <h3 className="text-blue f25 sBold pb-3">Actualités de votre agence</h3>
        <p className="text-darkGrey f15 regular mb-5">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris
        </p>
        <CustomButton
          variant="blue"
          text="Mon agence"
          width="165px"
          link="/mon-agence"
        />
      </div>
    </CardWrapper>
  );
};

export default NewsAgencyCard;
