import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import AccordionContainer from "./AccordionContainer/AccordionContainer";
import useResponsive from "../../../../hooks/useResponsive";
import "./BudgetSection.css";

const availablePeriods = [
  "Juillet 2021 à Juin 2022",
  "Juillet 2022 à Juin 2023",
  "Juillet 2023 à Juin 2024",
];

function BudgetSection() {
  const [period, setPeriod] = useState("Juillet 2021 à Juin 2022");
  const percentage = 34;
  const isMobileSize = useResponsive();
  const progressClass = isMobileSize ? "order-0 mb-2" : "order-2";
  return (
    <section className="budgetSection">
      <div className="d-flex justify-content-between align-items-center b-1-lightGrey ps-2 pe-3 py-3 mb-4 px-lg-5 py-lg-4 my-lg-5 br6">
        <p className="f18 mb-0 text-blue medium">
          Trésorie actuelle <br /> de l'immeuble Le Nathalia I
        </p>
        <div className="wm60">
          <p className="mb-0 text-end text-lg-center text-blue medium f15 ">
            Montant globale
          </p>
          <p className="mb-0 text-end text-lg-center f25 bold ">985,56 €</p>
        </div>
      </div>
      <h2 className="f18 sBold text-blue">Budget annuel de l'immeuble</h2>
      <div className="exerciceComptable px-4 px-lg-0 mt-5 d-flex flex-column flex-lg-row justify-content-center align-items-lg-center">
        <p className="f15 medium mb-lg-0 me-4">Exercice comptable</p>
        <Dropdown
          value={period}
          options={availablePeriods}
          onChange={(e) => setPeriod(e.value)}
        />
      </div>
      <div className="d-flex justify-content-center align-items-stretch mx-lg-5">
        <div className="w33 d-flex flex-column justify-content-center text-center text-lg-left bright-1-lightGrey ps-lg-5">
          <p className="text-blue f18 fm13 medium mb-1 mb">BUDGET</p>
          <p className="text-darkgrey f25 fm18 medium">15 421,50 €</p>
        </div>
        <div className="w33 d-flex flex-column justify-content-center text-center bright-1-lightGrey">
          <p className="text-blue f18 fm13 medium mb-1 mb">CONSOMMÉ</p>
          <p className="text-darkgrey f25 fm18 medium">5 257,50 €</p>
        </div>
        <div className="w33 d-flex flex-column align-items-end pe-lg-5">
          <div className="d-flex flex-column align-items-center">
            <p className="text-blue f18 fm13 medium order-1">CONSOMMATION</p>
            <div className={`progressCircleContainer ${progressClass}`}>
              <CircularProgressbar
                value={percentage}
                text={`${percentage}%`}
                strokeWidth="11"
                styles={buildStyles({
                  textSize: "25px",
                  pathColor: "#2d6aa5",
                  textColor: "#2c659d",
                  trailColor: "#E4EAF8",
                })}
              />
            </div>
          </div>
        </div>
      </div>
      <AccordionContainer />
    </section>
  );
}

export default BudgetSection;
