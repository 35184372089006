import React from "react";

import CustomButton from "../../../Common/CustomButton/CustomButton";
import ExtraitTable from "./ExtraitTable";
import "./ExtraitSection.css";

function ExtraitSection({ immeuble, activeIndex, setActiveIndex, index }) {
  const { NOM_IMM, ADR1, ADR2, ecrcopros: tableData } = immeuble;
  const open = index === activeIndex;
  const show = open ? "show" : "";
  const handleToggle = () => {
    if (!open) {
      setActiveIndex(index);
    } else {
      setActiveIndex(null);
    }
  };
  return (
    <section className="extraitSection mb-5">
      <div
        className="extraitHeader pointer p-3 br6 d-flex align-items-center justify-content-between"
        onClick={handleToggle}
      >
        <div>
          <h3 className="text-blue f18">Votre Immeuble {NOM_IMM}</h3>
          <p className="f15 mb-0">{ADR1 + ADR2}</p>
        </div>
        <i className={`bi bi-caret-down-fill ${show}`}></i>
      </div>
      <div className={`extraitBody ${show}`}>
        <div className="soldeBanner d-flex justify-content-between align-items-center b-1-lightGrey px-2 ps-lg-0 px-lg-5 py-3 my-lg-5 br6">
          <div className="wm-auto ps-lg-5">
            <p className="text-blue text-center medium mb-0">Solde débiteur</p>
            <p className="f25 bold mb-0 text-center wm100">9,41 €</p>
          </div>
          <div className="text-center">
            <p className="text-blue medium mb-0">Solde à payer</p>
            <p className="f25 bold mb-0">25,07 €</p>
          </div>
          <CustomButton text="Payer" variant="blue" />
        </div>
        <h2 className="text-blue mt-5 mb-4 ms-3 f18">Extrait de compte</h2>
        <ExtraitTable tableData={tableData} />
      </div>
    </section>
  );
}

export default ExtraitSection;
