import axios from "axios";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const useFetchExtraits = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const immeubles = useSelector((state) => state.immeublesReducer.immeubles);
  useEffect(() => {
    const fetchData = async () => {
      try {
        for (const immeuble of immeubles) {
          const immeubleData = {
            id: immeuble.id,
            NOM_IMM: immeuble.NOM_IMM,
            ADR1: immeuble.ADR1,
            ADR2: immeuble.ADR2,
            CODE: immeuble.CODE,
            IIII: immeuble.IIII,
          };
          let ecrcopros = await axios.get(
            `https://taboni-soft-api.labo-linkibe.fr/api/ecrcopros?filters[IIII][$eq]=${immeuble.IIII}&filters[CCCC][$eq]=${immeuble.CCCC}`
          );
          ecrcopros = ecrcopros.data.data;
          setData((prevState) => {
            return [
              ...prevState,
              {
                ...immeubleData,
                ecrcopros: ecrcopros,
              },
            ];
          });
        }

        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [immeubles]);

  return { data, loading, error };
};

export default useFetchExtraits;
