import React from "react";
import { useSelector } from "react-redux";

import user_photo from "../../../Images/Images/photo-user.jpg";
import CustomButton from "../../Common/CustomButton/CustomButton";
import CustomInput from "../../Common/CustomInput/CustomInput";
import "./ProfileForm.css";

function ProfileForm() {
  const user = useSelector((state) => state.usersReducer.user);
  return (
    <form className="profileForm">
      <h2 className="f18 sBold text-blue d-none d-lg-block">
        Informations générales
      </h2>
      <div className="d-flex flex-column flex-lg-row align-items-center pt-4 py-lg-5 px-2">
        <div className="profileImageContainer d-flex justify-content-center align-self-lg-start position-relative pointer mx-5">
          <img
            src={user_photo}
            alt="profile"
            className="br50"
            width="141px"
            height="141px"
          />
          <div className="iconContainer d-flex align-items-center justify-content-center bg-blue br50">
            <i className="bi bi-camera text-white f15"></i>
          </div>
        </div>
        <div className="ms-lg-5 mt-5 mt-lg-0 w70 wm100">
          <CustomInput
            placeholder="Nom et Prénom"
            className="mb-4"
            defaultValue={user.Nom}
            disabled
          />
          <CustomInput
            placeholder="Poste"
            className="mb-4"
            defaultValue={user.Titre}
            disabled
          />

          <CustomInput
            placeholder="Date de naissance"
            className="mb-4"
            disabled
          />
          <CustomInput
            placeholder="Adresse"
            className="mb-4"
            defaultValue={user.Adr1}
            disabled
          />
          <CustomInput
            placeholder="E-mail"
            className="mb-4"
            defaultValue={user.Email}
            disabled
          />
          <CustomInput
            placeholder="Téléphone"
            className="mb-4"
            defaultValue={user.Tel}
            disabled
          />
          <CustomInput placeholder="Mot de passe" className="mb-4" disabled />
          <CustomInput
            placeholder="Confirmer mot de passe"
            className="mb-4"
            disabled
          />
          <div className="d-flex justify-content-center justify-content-lg-end">
            <CustomButton
              text="Annuler"
              variant="blueOutlined"
              className="me-3"
              width="125px"
            />
            <CustomButton
              text="Sauvegarder"
              variant="blue"
              borderColor="blue"
              width="135px"
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default ProfileForm;
