import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CustomButton from "../Components/Common/CustomButton/CustomButton";
import CustomInput from "../Components/Common/CustomInput/CustomInput";
import { PolygoneSVG } from "../Components/Svgs";
import useResponsive from "../hooks/useResponsive";
import logo1 from "../Images/Images/logo-1.png";
import logo2 from "../Images/Images/logo-2.png";
import logo3 from "../Images/Images/logo-3.png";
import { userLogin } from "../REDUX/actions/users";

import "../ViewsStyles/LoginView.css";

function LoginView() {
  const isMobileSize = useResponsive();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loading, setLoading] = useState(false);

  const eyeClass = showPassword ? "eye-slash-fill" : "eye-fill";
  const passwordFieldType = showPassword ? "text" : "password";
  const dispatch = useDispatch();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(userLogin());
    try {
      let response = await axios.post(
        `https://taboni-soft-api.labo-linkibe.fr/api/auth/local`,
        {
          identifier: email,
          password: password,
        }
      );
      localStorage.setItem("accessToken", response.data.jwt);
      setLoading(false);

      window.location.reload();
    } catch (err) {
      setLoading(false);
      setLoginError(true);
    }
  };
  return (
    <section className="loginView px-5 px-lg-0 d-flex align-items-center justify-content-center">
      <form
        onSubmit={handleSubmit}
        className="loginForm w35 p-lg-5 pb-4 br20 bg-levenderGrey d-flex flex-column justify-content-center justify-content-lg-end align-items-center position-relative"
      >
        <PolygoneSVG color="#F3CD4B" id="polygone1" />
        <PolygoneSVG
          color="#2C659D"
          id="polygone2"
          rotate={125}
          translate={"120, 27"}
        />

        <PolygoneSVG
          color="#F3CD4B"
          id="polygone3"
          rotate={350}
          translate={"0,0"}
        />
        <PolygoneSVG
          color="#2C659D"
          id="polygone4"
          rotate={75}
          translate={"90,0"}
        />
        <div className="logoContainer bg-yellow d-flex flex-column align-items-center justify-content-center br50 pb-2 position-absolute">
          <img src={logo1} alt="logo" width="50px" />
          <img src={logo2} alt="logo" width="70px" className="mt-1" />
          <img src={logo3} alt="logo" width="90px" />
        </div>
        <h1 className="f30 sBold text-yankeesBlue mb-3 mt-4">Connexion</h1>
        <CustomInput
          placeholder="Email"
          className="w80 mb-3"
          callback={handleEmailChange}
          autoComplete="username"
        />
        <div className="w80 mb-2 position-relative">
          <CustomInput
            placeholder="Mot de passe"
            type={passwordFieldType}
            callback={handlePasswordChange}
            autoComplete="current-password"
          />
          <i
            className={`bi bi-${eyeClass} f20 passwordEye position-absolute pointer`}
            onClick={() => setShowPassword(!showPassword)}
          ></i>
        </div>
        <Link className="f12 w80 text-yankeesBlue d-flex justify-content-end">
          Mot de passe oublié ?
        </Link>
        {loginError ? (
          <div className="py-3 my-3 bg-red br6 w80">
            <p className="mb-0 text-white text-center">
              <i className="bi bi-exclamation-circle-fill pe-3"></i>
              Mot de passe ou Identifiant incorrect!
            </p>
          </div>
        ) : null}
        <CustomButton
          text="Me connecter"
          prefixIcon="box-arrow-in-right"
          variant="yellow"
          className="loginBtn my-2 w50"
          isSubmit
          loading={loading}
          width={isMobileSize ? "200px" : ""}
        />
      </form>
    </section>
  );
}

export default LoginView;
