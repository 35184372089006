import React from "react";
import Polygone from "../../../../../Images/Images/dashboard/Polygone5.png";
import ReportItem from "./ReportItem/ReportItem";
import SignalerModal from "../../../../Common/SignalerModal/SignalerModal";
import "./ReportsDashboard.css";
import { Link } from "react-router-dom";

const ReportsDashboard = ({ evenementsData }) => {
  return (
    <div className="reportsDashboard bg-white w55 wm100 br5 position-relative pt-4 px-4 h100 hm-auto ">
      <div className="position-absolute">
        <img src={Polygone} alt="polygone" />
      </div>
      <div className="d-flex align-items-center justify-content-between pt-2 mb-4">
        <h3 className="f25 text-blue sBold mb-0 position-relative">
          Signalements
        </h3>
        <SignalerModal circular />
      </div>
      {evenementsData.map((eventData) => {
        return <ReportItem key={eventData.id} eventData={eventData} />;
      })}
      <Link
        preventScrollReset={false}
        to="/signalements"
        className="d-block text-blue f15 medium py-3 position-relative"
      >
        Voir tous les signalements
      </Link>
    </div>
  );
};

export default ReportsDashboard;
