/* -------------------------------------------------------------------------- */
/*                                dependencies                                */
/* -------------------------------------------------------------------------- */
// packages
import React from "react";

/* -------------------------------------------------------------------------- */
/*                              common component                              */
/* -------------------------------------------------------------------------- */
const ContactTag = ({ img, name, email }) => {
  /* ******************************** rendering ******************************* */
  return (
    <div className="d-flex align-items-center position-relative">
      <img className="contactImg" src={img} alt={name} />
      <div>
        <h5 className="f15 text-dark medium m-0">{name}</h5>
        <a href={`mailto:${email}`} className="f14">
          {email}
        </a>
      </div>
    </div>
  );
};

export default ContactTag;
