import React from "react";
import { useDispatch } from "react-redux";
import useResponsive from "../../../../hooks/useResponsive";
import { openForm } from "../../../../REDUX/actions/signalementForm";

import "./SignalerButton.css";

function SignalerButton({ setShow, buttonText, circular }) {
  const circularClass = circular ? "circularBtn br50 ps-2" : "ps-3";
  const circularIconClass = circular ? "ps-0 pe-4" : "ps-2";
  const isMobileSize = useResponsive();
  const dispatch = useDispatch();
  const handleClick = () => {
    isMobileSize ? dispatch(openForm()) : setShow(true);
  };
  return (
    <button
      className={`signalerButton d-flex align-items-center justify-content-center f15 pe-2 br5 bg-yellow text-white transition02 ${circularClass}`}
      onClick={handleClick}
    >
      {!circular ? <span>{buttonText}</span> : null}
      <i className={`bi bi-plus f25 ${circularIconClass}`}></i>
    </button>
  );
}

export default SignalerButton;
