import React, { useState } from "react";
import SelectPaiement from "./SelectPaiement";
const SelectPaiementWrapper = () => {
  // state
  const [selectedInput, setSelectedInput] = useState("");
  /* ******************************** Rendering ******************************* */
  return (
    <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between">
      <SelectPaiement
        paiementMethod="Paiement ponctuel"
        setSelectedInput={setSelectedInput}
        selectedInput={selectedInput}
      ></SelectPaiement>
      <SelectPaiement
        paiementMethod="Prélèvement automatique"
        setSelectedInput={setSelectedInput}
        selectedInput={selectedInput}
        className="mt-4 mt-lg-0"
      ></SelectPaiement>
    </div>
  );
};

export default SelectPaiementWrapper;
