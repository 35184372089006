export const votesList = [
  {
    id: 1,
    title: "Immeuble Le Nathalia I",
    echeance: "Samedi le 16 juillet 2022",
    date: "Mardi le 19 juillet 2022 à 18h00",
    lieu: "Cabinet Taboni SAS 82 bd Gambetta 06000 Nice",
    done: false,
  },
  {
    id: 2,
    title: "Immeuble Les arts bat.b",
    echeance: "Vendredi 11 Mars 2022",
    date: "Mardi 25 Mars 2022 à 18h00",
    lieu: "Cabinet Taboni SAS 82 bd Gambetta 06000 Nice",
    done: true,
  },
  {
    id: 3,
    title: "Immeuble Les arts bat.b",
    echeance: "Vendredi 11 Mars 2022",
    date: "Mardi 25 Mars 2022 à 18h00",
    lieu: "Cabinet Taboni SAS 82 bd Gambetta 06000 Nice",
    done: true,
  },
];

export const resolutionsList = [
  {
    id: 1,
    title: "01 . Nomination du président de l'assemblée génerale",
  },
  {
    id: 2,
    title: "02 . Nomination du président de l'assemblée génerale",
  },
  {
    id: 3,
    title: "03 . Nomination du président de l'assemblée génerale",
  },
  {
    id: 4,
    title: "04 . Nomination du président de l'assemblée génerale",
  },
  {
    id: 5,
    title: "05 . Nomination du président de l'assemblée génerale",
  },
  {
    id: 6,
    title: "06 . Nomination du président de l'assemblée génerale",
  },
  {
    id: 7,
    title: "07 . Nomination du président de l'assemblée génerale",
  },
  {
    id: 8,
    title: "08 . Nomination du président de l'assemblée génerale",
  },
];

export const filesList = [
  {
    id: 1,
    fileName: "Appel du 31-10-2021",
  },
  {
    id: 2,
    fileName: "Appel du 18-10-2020",
  },
  {
    id: 3,
    fileName: "Appel du 25-11-2020",
  },
  {
    id: 4,
    fileName: "Appel du 07-09-2021",
  },
];

export const resolutionTypesList = [
  {
    label: "Toutes les résolutions",
    value: "Toutes les résolutions",
  },
  {
    label: "résolution 1",
    value: "résolution 1",
  },
  {
    label: "résolution 2",
    value: "résolution 2",
  },
  {
    label: "résolution 3",
    value: "résolution 3",
  },
];
