import React, { useState, useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";
import { ValideSVG } from "../../../Svgs";

function AccordionContent({ signalement, statut }) {
  const { type, description, images } = signalement;
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  return (
    <div className="p-lg-4 pt-3">
      <div className="d-flex justify-content-between">
        <p className="mb-0 f14 wm100">{type}</p>
        <p className="mb-0 f15 medium text-blue d-none d-lg-block">
          {statut === "inProgress" ? "En cours" : "Traité"}
        </p>
      </div>
      <h2 className="f18 sBold w25 my-4 wm100">{description}</h2>
      <p className="f13 regular wm100">
        Nous avons appelé ce matin le prestatire, il passe cet aprés midi pour
        nous dépanner.
      </p>
      <p className="mb-0 mt-lg-5 f12  ms-2 ms-lg-0 regular">Postulé Par</p>
      <p className="f14 bold ms-2 ms-lg-0">Betten DORF</p>
      <div className="pb-5">
        {images.map(({ id, src }, index) => (
          <img
            key={id}
            src={src}
            onClick={() => openImageViewer(index)}
            alt="signalement"
            width="106px"
            height="84px"
            className="signalementImg br10 me-3 pointer mt-3 mt-lg-0"
          />
        ))}
        {isViewerOpen && (
          <ImageViewer
            src={images.map(({ src }) => {
              return src;
            })}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )}
      </div>

      <h2 className="f22 sBold text-blue mt-lg-5 mt-0 ">Nos Relances</h2>
      <div className="d-flex justify-content-between align-items-center py-5">
        <div className="text-dark">
          <p className="mb-0 f14 bold">L'entreprise X à été relancée</p>
          <p className="mb-0 f14">Le 08 juillet 2022</p>
        </div>
        <ValideSVG />
      </div>
      <div className="d-flex justify-content-between align-items-center py-5">
        <div className="text-dark">
          <p className="mb-0 f14 bold">L'entreprise X à été relancée</p>
          <p className="mb-0 f14">Le 08 juillet 2022</p>
        </div>
        <ValideSVG />
      </div>
      <div className="d-flex justify-content-between align-items-center py-5">
        <div className="text-dark">
          <p className="mb-0 f14 bold">L'entreprise X à été relancée</p>
          <p className="mb-0 f14">Le 08 juillet 2022</p>
        </div>
        <ValideSVG />
      </div>
    </div>
  );
}

export default AccordionContent;
