import React from "react";
import AssistanceModal from "../Common/AssistanceModal/AssistanceModal";
import Drawer from "./Drawer/Drawer";
import Header from "./Header/Header";
import MobileNavbar from "./MobileNavbar/MobileNavbar";
import "./Layout.css";

const Layout = ({ children }) => {
  return (
    <div>
      <Header />
      <div className="d-flex gap-4">
        <Drawer />
        <div className="layoutApp">
          <AssistanceModal />
          {children}
        </div>
      </div>
      <MobileNavbar />
    </div>
  );
};

export default Layout;
