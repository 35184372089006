const initialState = {
  user: null,
};

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return { ...state };
    case "AUTH_SUCCESS":
      return { ...state, user: action.payload };
    case "AUTH_ERROR":
      return { ...state };
    case "LOGOUT":
      return { ...state, user: null };
    default:
      return state;
  }
};
