import React from "react";
import { filesList } from "../../../../helpers/assembleVotesFakeData";
import { FolderSVG, PdfSVG } from "../../../Svgs";

import "./DocumentsList.css";

function DocumentsList({ filesCount, className }) {
  return (
    <div className={`b-1-lightGrey br5 mt-3 mt-lg-5 ${className}`}>
      <div className="listHeader d-flex align-items-center p-3">
        <div className="iconContainer d-flex justify-content-center align-items-center p-1 me-3 bg-lightGrey align-self-start br50">
          <FolderSVG color="#2c659d" />
        </div>
        <div>
          <p className="f14 medium mb-0">Documents annexes</p>
          <p className="f10 text-mediumGrey mb-0">{filesCount} fichiers</p>
        </div>
      </div>
      <div className="px-3">
        {filesList.map(({ id, fileName }) => (
          <div key={id} className="d-flex pointer align-items-center my-3">
            <PdfSVG />
            <p className="mb-0 ms-3 medium f13">{fileName}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DocumentsList;
