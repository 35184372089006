/* -------------------------------------------------------------------------- */
/*                                dependencies                                */
/* -------------------------------------------------------------------------- */
// packages :
import React from "react";
// style
import "./SideBar.css";
/* -------------------------------------------------------------------------- */
/*                                Layout Components                                 */
/* -------------------------------------------------------------------------- */
const SideBar = ({ children }) => {
  /* ******************************** Rendering ******************************* */
  return (
    <div className="sideBar d-none d-lg-block w20 position-sticky">
      {children}
    </div>
  );
};

export default SideBar;
