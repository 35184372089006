import React from "react";
import { notificationsData } from "../../../helpers/notificationsFakeData";
import NotificationItem from "./NotificationItem";
import "./NotificationWrapper.css";

const NotificationWrapper = ({ show, forMobile }) => {
  const topPosition = notificationsData.length * 97 + 103;
  const arrowClass = forMobile ? "bottomArrow" : "topArrow";
  return (
    <div
      className="notificationWrapper py-2 px-3 br5"
      style={{
        display: show ? "block" : "none",
        top: forMobile ? -topPosition : "",
      }}
    >
      <i className={`bi bi-triangle-fill ${arrowClass}`}></i>
      {notificationsData.map(({ id }) => (
        <NotificationItem key={id} />
      ))}
      <button
        className="unset f15 text-blue medium text-center mb-2 mt-3 mx-auto d-flex align-items-center justify-content-center"
        type="button"
      >
        voir toutes les notifications
      </button>
    </div>
  );
};

export default NotificationWrapper;
