import contact_1_Photo from "../Images/Images/contacts/contact-1.png";
import contact_2_Photo from "../Images/Images/contacts/contact-2.png";
import contact_3_Photo from "../Images/Images/contacts/contact-3.png";
import contact_4_Photo from "../Images/Images/contacts/contact-4.png";
import contact_5_Photo from "../Images/Images/contacts/contact-5.png";
import contact_6_Photo from "../Images/Images/contacts/contact-6.png";
import contact_7_Photo from "../Images/Images/contacts/contact-7.png";
import contact_8_Photo from "../Images/Images/contacts/contact-8.png";
import contact_9_Photo from "../Images/Images/contacts/contact-9.png";

export const contactsData = [
  {
    id: 1,
    photo: contact_1_Photo,
    nom: "lorem ipsum",
    fonction: "responsable",
    email: "text@test.fr",
    phone: "06 05 04 03 02",
  },
  {
    id: 2,
    photo: contact_2_Photo,
    nom: "lorem ipsum",
    fonction: "responsable",
    email: "text@test.fr",
    phone: "06 05 04 03 02",
  },
  {
    id: 3,
    photo: contact_3_Photo,
    nom: "lorem ipsum",
    fonction: "responsable",
    email: "text@test.fr",
    phone: "06 05 04 03 02",
  },
  {
    id: 4,
    photo: contact_4_Photo,
    nom: "lorem ipsum",
    fonction: "responsable",
    email: "text@test.fr",
    phone: "06 05 04 03 02",
  },
  {
    id: 5,
    photo: contact_5_Photo,
    nom: "lorem ipsum",
    fonction: "responsable",
    email: "text@test.fr",
    phone: "06 05 04 03 02",
  },
  {
    id: 6,
    photo: contact_6_Photo,
    nom: "lorem ipsum",
    fonction: "responsable",
    email: "text@test.fr",
    phone: "06 05 04 03 02",
  },
  {
    id: 7,
    photo: contact_7_Photo,
    nom: "lorem ipsum",
    fonction: "responsable",
    email: "text@test.fr",
    phone: "06 05 04 03 02",
  },
  {
    id: 8,
    photo: contact_8_Photo,
    nom: "lorem ipsum",
    fonction: "responsable",
    email: "text@test.fr",
    phone: "06 05 04 03 02",
  },
  {
    id: 9,
    photo: contact_9_Photo,
    nom: "lorem ipsum",
    fonction: "responsable",
    email: "text@test.fr",
    phone: "06 05 04 03 02",
  },
];
