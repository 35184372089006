import React from "react";
import { AccordionTab } from "primereact/accordion";

import Accordion from "../../../Common/Accordion/Accordion";
import DocumentDetail from "../../../Common/DocumentDetail/DocumentDetail";
import Separator from "../../../Common/Separator/Separator";

import AccordionHeader from "./AccordionHeader";

import { accordionData } from "../../../../helpers/bienAccordionFakeData";
import "./AccordionSection.css";

function AccordionSection({ propertiesData }) {
  return (
    <div className="accordionSection">
      <Accordion>
        {propertiesData.map(({ id, NOM_IMM }, index) =>
          NOM_IMM !== "" ? (
            <AccordionTab
              key={id}
              headerTemplate={
                <AccordionHeader
                  propertyName={NOM_IMM}
                  floorNumber={0}
                  price="25,07"
                  index={index}
                />
              }
            >
              <div className="mb-5">
                <h2 className="text-blue f18 sBold mb-4">Documents</h2>
                <div className="documentsContainer">
                  {accordionData[0].documents.map(
                    ({ id, title, description, date }) => {
                      return (
                        <DocumentDetail
                          key={id}
                          title={title}
                          description={description}
                          date={date}
                          titleFontSize={12}
                        />
                      );
                    }
                  )}
                </div>
              </div>
              <Separator />
              <div className="my-5">
                <h2 className="text-blue f18 sBold mb-4">Extrait</h2>
                <div className="documentsContainer">
                  {accordionData[0].extraits.map(
                    ({ id, title, description, date }) => {
                      return (
                        <DocumentDetail
                          key={id}
                          title={title}
                          description={description}
                          date={date}
                          titleFontSize={12}
                        />
                      );
                    }
                  )}
                </div>
              </div>
            </AccordionTab>
          ) : null
        )}
      </Accordion>
    </div>
  );
}

export default AccordionSection;
