import React from "react";

function ErrorView() {
  return (
    <h2 className="ms-5 mt-5">
      Une erreur est survenue... ! Veuillez réessayer plus tard.
    </h2>
  );
}

export default ErrorView;
