import axios from "axios";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addQualites } from "../REDUX/actions/qualites";
import { addImmeubles } from "../REDUX/actions/immeubles";
import { authSuccess, userLogout } from "../REDUX/actions/users";

const useFetchUser = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({ authenticated: false });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("accessToken");
      try {
        let user = await axios.get(
          `https://taboni-soft-api.labo-linkibe.fr/api/users/me`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        user = user.data;
        let qualites = await axios.get(
          `https://taboni-soft-api.labo-linkibe.fr/api/qualites?filters[ID_PERSO][$eq]=${user.ID_PERSO}&fields[0]=ID_PERSO&fields[1]=CODE&fields[2]=MMMM&fields[3]=IIII&fields[4]=CCCC&fields[5]=LLLL&fields[6]=XXXX`
        );
        qualites = qualites.data?.data || [];
        dispatch(addQualites(qualites));
        let immeubles = [];
        if (qualites.length) {
          for (let qualite of qualites) {
            let ImmeubleCode = qualite.attributes.IIII;
            let immeuble = await axios.get(
              `https://taboni-soft-api.labo-linkibe.fr/api/immeubles?filters[IIII][$eq]=${ImmeubleCode}`
            );
            immeuble = immeuble.data.data[0];
            immeubles.push({
              id: immeuble.id,
              NOM_IMM: immeuble.attributes.NOM_IMM,
              IIII: immeuble.attributes.IIII,
              ADR1: immeuble.attributes.ADR1,
              ADR2: immeuble.attributes.ADR2,
              CCCC: qualite.attributes.CCCC,
              LLLL: qualite.attributes.LLLL,
              MMMM: qualite.attributes.MMMM,
              XXXX: qualite.attributes.XXXX,
              CODE: qualite.attributes.CODE,
            });
          }
          dispatch(addImmeubles(immeubles));
        }

        dispatch(authSuccess(user));
        setData({ authenticated: true });
        setLoading(false);
      } catch (error) {
        let err = error.response?.data.error;
        if (err?.status === 401) {
          dispatch(userLogout());
          setData({ authenticated: false });
        } else {
          dispatch(userLogout());
          setData({ authenticated: false });
          setError(error);
        }
        setLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  return { data, loading, error };
};

export default useFetchUser;
