import React from "react";
import { Link } from "react-router-dom";

import "./ProfileLink.css";
function ProfileLink({ profilePic, fullName, description }) {
  return (
    <Link to="/profile">
      <div className="profileLink d-flex align-items-center p-2 br10 pointer">
        <img
          src={profilePic}
          alt="profile"
          width="43px"
          height="43px"
          className="br50 me-3"
        />
        <div>
          <p className="mb-0 f12 bold text-white">{fullName}</p>
          <p className="mb-0 f12 text-white">{description}</p>
        </div>
      </div>
    </Link>
  );
}

export default ProfileLink;
