import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";

function CustomButtonCore({
  text,
  callBack,
  variant,
  borderColor,
  prefixIcon,
  width,
  hasFullWidth,
  className,
  isSubmit,
  loading,
}) {
  const widthClass = hasFullWidth ? "w100" : "";
  const borderClass = borderColor ? `${borderColor}Border` : "";
  const customClass = className || "";
  const paddingClass = text ? "pe-3" : "";
  return (
    <button
      className={`customButton f15 px-3 br5 medium transition02 ${variant} ${widthClass} ${borderClass} ${customClass}`}
      style={{ width: width }}
      onClick={callBack}
      type={isSubmit ? "submit" : "button"}
    >
      {loading ? (
        <ProgressSpinner
          style={{
            display: "flex",
            alignItems: "center",
            width: "30px",
            height: "30px",
          }}
          strokeWidth={5}
          className="buttonSpinner"
        />
      ) : (
        <>
          {prefixIcon ? (
            <i className={`bi bi-${prefixIcon} prefix ${paddingClass}`} />
          ) : null}
          {text}
        </>
      )}
    </button>
  );
}

export default CustomButtonCore;
