const initialState = {
  open: false,
};

export const drawerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_OPEN":
      return { open: !state.open };
    case "CLOSE_DRAWER":
      return { open: false };
    default:
      return state;
  }
};
