import React from "react";
import CustomIconContainer from "../../../Common/CustomIconContainer/CustomIconContainer";
import { MailSVG, PhoneSVG } from "../../../Svgs";

function TableRow({ id, photo, nom, fonction, email, phone, isMobileSize }) {
  return !isMobileSize ? (
    <div className="tableRow d-flex justify-content-between align-items-center ps-4 py-3 b-1-lightGrey border-top-0">
      <img src={photo} alt="user" height="41" width="41" />
      <p className="mb-0 f12 medium">{nom}</p>
      <p className="mb-0 f12 d-none d-lg-block">{fonction}</p>
      <a href={`mailto:${email}`} className="mb-0 f12 d-none d-lg-block">
        {email}
      </a>
      <p className="mb-0 f12 d-none d-lg-block">{phone}</p>
    </div>
  ) : (
    <div className="tableRow d-flex justify-content-between p-3 b-1-lightGrey border-top-0">
      <div className="d-flex align-items-center">
        <img src={photo} alt="user" height="41" width="41" />
        <p className="mb-0 f12 medium ps-3">{nom}</p>
      </div>
      <div className="d-flex">
        <CustomIconContainer variant="white" className="me-2">
          <PhoneSVG />
        </CustomIconContainer>
        <CustomIconContainer variant="blue">
          <MailSVG />
        </CustomIconContainer>
      </div>
    </div>
  );
}

export default TableRow;
