const initialState = {
  immeubles: null,
};

export const immeublesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_IMMEUBLES":
      return { ...state, immeubles: action.payload };

    default:
      return state;
  }
};
