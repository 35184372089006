import React, { useState, useEffect } from "react";

import SignalementSection from "../Components/Pages/Signalements/SignalementsSection/SignalementsSection";
import ContactSection from "../Components/Pages/Signalements/ContactSection/ContactSection";

import SignalerModal from "../Components/Common/SignalerModal/SignalerModal";
import BreadCrumbHeader from "../Components/Common/BreadCrumbHeader/BreadCrumbHeader";
import TabsNavbar from "../Components/Common/TabsNavbar/TabsNavbar";
import SideBar from "../Components/Common/SideBar/SideBar";
import InformationCard from "../Components/Common/InformationCard/informationCard";
import ContactCard from "../Components/Common/CardWrapper/ContactCard";
import SignalementNew from "../Components/Pages/Signalements/SignalementNew/SignalementNew";

import { contactsData } from "../helpers/contactCardFakeData";
import { changeTab } from "../REDUX/actions/tabsNavbar";
import { useDispatch, useSelector } from "react-redux";
import { closeForm } from "../REDUX/actions/signalementForm";

import useResponsive from "../hooks/useResponsive";
import useScrollToTop from "../hooks/useScrollToTop";

import "../Components/Pages/Signalements/Signalements.css";
import ViewHeader from "../Components/Common/ViewHeader/ViewHeader";
import useFetchSignalements from "../hooks/useFetchSignalements";
import ViewWrapper from "../Components/Common/ViewWrapper/ViewWrapper";

const SignalementsView = () => {
  useScrollToTop();
  const [activeTab, setActiveTab] = useState("Signalements");
  const isMobileSize = useResponsive();

  const showForm = useSelector(
    (state) => state.signalementFormReducer.showForm
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isMobileSize === false) {
      dispatch(closeForm());
    }
    dispatch(changeTab({ payload: activeTab }));
  }, [isMobileSize, dispatch, activeTab]);

  const user = useSelector((state) => state.usersReducer.user);
  const { data, loading, error } = useFetchSignalements(user.ID_PERSO);

  return (
    <ViewWrapper loading={loading} error={error}>
      <div className="signalementsView d-flex flex-column">
        <ViewHeader>
          <BreadCrumbHeader
            title="Signalements"
            subtitle={activeTab === "contact" ? "contact" : null}
          />
          <TabsNavbar
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabs={["Signalements", "contact"]}
            className="wm100 mb-lg-0"
            sticky
          />
        </ViewHeader>
        <div className="d-flex justify-content-between">
          <div className="signalementsMain w75 wm100 px-2 px-lg-0">
            {activeTab === "Signalements" && showForm === false ? (
              <SignalementSection signalements={data} />
            ) : showForm === true ? (
              <SignalementNew />
            ) : (
              <ContactSection />
            )}
          </div>
          <SideBar>
            <InformationCard />
            {activeTab === "Signalements" ? (
              <ContactCard title="Vos Contacts" cardData={contactsData} />
            ) : (
              <SignalerModal buttonText="SIGNALEMENT" />
            )}
          </SideBar>
        </div>
      </div>
    </ViewWrapper>
  );
};

export default SignalementsView;
