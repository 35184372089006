import React from "react";
import { AccordionTab } from "primereact/accordion";

import SignalerModal from "../../../Common/SignalerModal/SignalerModal";
import Accordion from "../../../Common/Accordion/Accordion";

import AccordionHeader from "./AccordionHeader";
import AccordionContent from "./AccordionContent";

import "./SignalementsSection.css";
import TableHeader from "../../../Common/TableHeader/TableHeader";

function SignalementsSection({ signalements }) {
  return (
    <section className="signalementsSection mt-4">
      <div className="d-none d-lg-flex justify-content-end mb-4">
        <SignalerModal buttonText="Signaler" />
      </div>
      <TableHeader
        columns={[
          { text: "Date", width: "30" },
          { text: "Signalement", width: "33" },
          { text: "Statut", width: "27" },
          { text: "Compte rendu", width: "10" },
        ]}
        className="d-none d-lg-flex"
      />
      <Accordion>
        {signalements.map(({ id, attributes }) => {
          const { date, type, obj1, obj2, obj3, obj4, traite } = attributes;
          const description = obj1 + obj2 + obj3 + obj4;
          return (
            <AccordionTab
              key={id}
              headerTemplate={
                <AccordionHeader
                  date={date}
                  signalement={{
                    type: type,
                    description: description,
                  }}
                  statut={traite}
                />
              }
            >
              <AccordionContent
                signalement={{
                  type: traite,
                  description: description,
                  images: [],
                }}
                statut={traite}
              />
            </AccordionTab>
          );
        })}
      </Accordion>
    </section>
  );
}

export default SignalementsSection;
