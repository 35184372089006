import React from "react";

function TableRow({
  id,
  date,
  libelle,
  depense,
  recettes,
  solde,
  isMobileSize,
}) {
  return !isMobileSize ? (
    <div className="tableRow d-flex justify-content-between align-items-center p-4 pe-2 b-1-lightGrey border-top-0 f12">
      <p className="mb-0 w15">{date}</p>
      <p className="mb-0 w40">{libelle}</p>
      <p className="mb-0 w15 text-center">{depense}</p>
      <p className="mb-0 w15 text-center">{recettes}</p>
      <p className="mb-0 w15 text-center">{solde}</p>
    </div>
  ) : (
    <div className="tableRow f12 ps-1 py-2 d-flex justify-content-between align-items-center  b-1-lightGrey border-top-0">
      <div className="d-flex flex-column w43">
        <p className="mb-0">{date}</p>
        <p className="mb-0 sBold">{libelle}</p>
      </div>
      <p className="mb-0 w15 text-center">{depense}</p>
      <p className="mb-0 w15 text-center">{recettes}</p>
      <p className="mb-0 w15 text-center">{solde}</p>
    </div>
  );
}

export default TableRow;
