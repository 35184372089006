import React from "react";

function FilterTag({ tagName, filter, setFilter }) {
  const activeBgClass =
    filter === tagName ? "b-1-yellow bg-yellow" : "b-1-lightGrey";
  const activeTextClass =
    filter === tagName ? "f16 regular text-white" : "f16 medium ";
  return (
    <div
      className={`p-2 me-2 mb-2 w-auto br6 d-inline-block ${activeBgClass}`}
      onClick={() => setFilter(tagName)}
    >
      <p className={`mb-0 ${activeTextClass}`}>{tagName}</p>
    </div>
  );
}

export default FilterTag;
