import React from "react";
import PropertyCard from "./PropertyCard/PropertyCard";

function PropertyCardsSection({ propertiesData }) {
  return propertiesData.map((propertyData) => {
    const { NOM_IMM, id } = propertyData;
    if (NOM_IMM) {
      return <PropertyCard key={id} propertyData={propertyData} />;
    } else {
      return null;
    }
  });
}

export default PropertyCardsSection;
