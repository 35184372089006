import React from "react";

import useResponsive from "../../../../hooks/useResponsive";
import TableHeader from "../../../Common/TableHeader/TableHeader";

import DocumentDetail from "../../../Common/DocumentDetail/DocumentDetail";

function DocumentsTable({ documentsData }) {
  const isMobileSize = useResponsive();

  const columnsList = isMobileSize
    ? [{ text: "Nom du document" }]
    : [
        { text: "Nom du document", width: 42 },
        { text: "Catégories", width: 30 },
        {
          text: "Dernière modification",
          width: 28,
          padding: "0px 0px 0px 5%",
        },
      ];
  return (
    <div className="mt-4">
      <TableHeader leftPadding={3} columns={columnsList} />
      <div className="py-3 b-1-lightGrey border-top-0 br0066">
        {documentsData.map(({ id, title, description, date }) => (
          <DocumentDetail
            key={id}
            title={title}
            description={description}
            date={date}
            className="mx-3 mt-0 pt-2"
          />
        ))}
      </div>
    </div>
  );
}

export default DocumentsTable;
