import axios from "axios";

export const getArticles = (setArticles) => {
  axios
    .get(
      `${process.env.REACT_APP_PPROD_API_URL}/articles?populate[banner][populate][0]=image`
    )
    .then(({ data }) => {
      setArticles(data.data);
    })
    .catch((err) => {
      throw err;
    });
};
export const getArticle = (id, setArticle, setLoading) => {
  axios
    .get(
      `${process.env.REACT_APP_PPROD_API_URL}/articles/${id}?populate[banner][populate][0]=image&populate[sections][populate][0]=content&populate[sections][populate][1]=image`
    )
    .then(({ data }) => {
      const coverImage =
        data.data.attributes.banner.data.attributes.image.data.attributes.url;
      let sections = [];
      data.data.attributes.sections.data.forEach((section) => {
        sections.push({
          content: section.attributes.content,
          image: section.attributes.image,
          title: section.attributes.title,
        });
      });

      setArticle({ ...data.data, sections: sections, coverImage: coverImage });
      setLoading(false);
    })
    .catch((err) => {
      throw err;
    });
};
