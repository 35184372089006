import React from "react";
import "./ProgressBar.css";

function ProgressBar({ percentage }) {
  return (
    <div className="customProgressBar br3 bg-levenderGrey">
      <div className="fill br3" style={{ width: `${percentage}%` }}></div>
    </div>
  );
}

export default ProgressBar;
