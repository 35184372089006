import React, { useState, useEffect } from "react";
import BreadCrumbHeader from "../Components/Common/BreadCrumbHeader/BreadCrumbHeader";
import SideBar from "../Components/Common/SideBar/SideBar";
import CommonArticleCard from "../Components/Common/CardWrapper/ArticleCard";
import InformationCard from "../Components/Common/InformationCard/informationCard";
import ViewHeader from "../Components/Common/ViewHeader/ViewHeader";
import { getArticle } from "../services/ArticlesService";
import { useParams } from "react-router-dom";
import "../ViewsStyles/ArticleView.css";
import { ProgressSpinner } from "primereact/progressspinner";

const ArticleView = () => {
  const [article, setArticle] = useState([]);
  const [loading, setLoading] = useState(true);
  const params = useParams();

  useEffect(() => {
    getArticle(params.id, setArticle, setLoading);
  }, [params]);
  console.log(article);

  return !loading ? (
    <>
      <ViewHeader>
        <BreadCrumbHeader title={article.attributes?.title} withGoBack />
      </ViewHeader>
      <div className="w100 d-flex justify-content-between pt-3">
        <div className="w75 wm100 ps-lg-4">
          <section className="w100">
            <img
              src={
                "https://taboni-api-pprod.labo-linkibe.fr/" + article.coverImage
              }
              alt=""
              className="ArticleViewImg w100 br5"
            />
            {article.sections.map((section) => (
              <div className="d-flex justify-content-between flex-column mb-5">
                {section.image.data ? (
                  <div className="w100 d-flex justify-content-center">
                    <img
                      src={
                        "https://taboni-api-pprod.labo-linkibe.fr/" +
                        section.image.data.attributes.url
                      }
                      alt={section.image.data.attributes.hash}
                      className="py-5 w50"
                    />
                  </div>
                ) : null}
                <div>
                  <h2 className="">{section.title}</h2>
                  <div
                    dangerouslySetInnerHTML={{ __html: section.content }}
                  ></div>
                </div>
              </div>
            ))}
          </section>
        </div>
        <SideBar>
          <InformationCard />
          <CommonArticleCard />
        </SideBar>
      </div>
    </>
  ) : (
    <ProgressSpinner />
  );
};

export default ArticleView;
