import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import SignalerModal from "../../Common/SignalerModal/SignalerModal";
import NotificationPanel from "../Header/NotificationPanel/NotificationPanel";

import { ContactSVG } from "../../Svgs";
import { mobileOnglets } from "../Drawer/Onglets";

import "./MobileNavbar.css";

function MobileNavbar() {
  const location = useLocation();
  const activeTab = useSelector((state) => state.tabsNavbarReducer.activeTab);
  const contactColorClass =
    location.pathname === "/contact" ? "text-lazuliBlue" : "text-dark";
  const contactIconColor =
    location.pathname === "/contact" ? "#2d6aa5" : "black";
  return (
    <div className="mobileNavbar d-flex justify-content-evenly align-items-center d-lg-none br5 bg-white">
      {mobileOnglets.map(({ name, icon, to }, index) => {
        const iconColorClass = location.pathname === to ? "#2D6AA5" : "#2C2C2C";
        const textColorClass =
          location.pathname === to ? "text-lazuliBlue" : "text-dark";
        if (
          location.pathname === "/signalements" &&
          activeTab === "Signalements" &&
          name === "Signalements"
        ) {
          return <SignalerModal circular key={index} />;
        } else {
          return (
            <Link
              to={to}
              key={index}
              className={`text-decoration-none ${textColorClass}`}
            >
              <div className="d-flex flex-column align-items-center justify-content-center">
                {icon(iconColorClass)}
                <p className="f12 mb-0 sBold linkTitle">{name}</p>
              </div>
            </Link>
          );
        }
      })}
      <NotificationPanel forMobile />
      <Link
        to="/contact"
        className={`text-decoration-none ${contactColorClass}`}
      >
        <div className="d-flex flex-column align-items-center justify-content-center">
          <ContactSVG color={contactIconColor} />
          <p className="f12 mb-0 sBold linkTitle">Contact</p>
        </div>
      </Link>
    </div>
  );
}

export default MobileNavbar;
