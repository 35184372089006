import React from "react";

import PaiementSection from "../PaiementSection/PaiementSection";
import BudgetSection from "../BudgetSection/BudgetSection";
import ExtraitsContainer from "../ExtraitSection/ExtraitsContainer";
import ExtraitSection from "../ExtraitSection/ExtraitSection";

function TabContentContainer({ activeTab }) {
  if (activeTab === "Extrait de compte") {
    return <ExtraitsContainer />;
  } else if (activeTab === "Paiement") {
    return <PaiementSection />;
  } else {
    return <BudgetSection />;
  }
}
export default TabContentContainer;
