import React, { useState } from "react";
import AssistanceButton from "./AssistanceButton/AssistanceButton";
import CustomButton from "../../Common/CustomButton/CustomButton";

import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";

import "./AssistanceModal.css";
import { servicesOptions } from "../../../helpers/assistanceModalFakeData";

function AssistanceModal() {
  const [open, setOpen] = useState(false);
  const [service, setService] = useState("");
  return (
    <div className="assistanceModal w20 d-none d-lg-block">
      {open ? (
        <form className="assistanceForm">
          <div className="formHeader d-flex align-items-center justify-content-center bg-lightBlue text-blue">
            <p className="f15 medium mb-0">Envoi un e-mail</p>
            <i
              className="bi bi-dash closeIcon pointer d-flex justify-content-center align-items-center"
              onClick={() => setOpen(false)}
            ></i>
          </div>
          <div className="formBody d-flex flex-column p-3 pb-5 bg-white ">
            <Dropdown
              value={service}
              options={servicesOptions}
              onChange={(e) => setService(e.value)}
              placeholder="Choix du service"
              panelClassName="assistanceDropdown"
            />
            <InputText placeholder="Object" name="object" />
            <InputTextarea rows={5} cols={30} placeholder="Message" />
          </div>
          <div className="formFooter">
            <CustomButton text="Envoyer" variant="blue" hasFullWidth />
          </div>
        </form>
      ) : (
        <div className="d-flex justify-content-end">
          <AssistanceButton setOpen={setOpen} />
        </div>
      )}
    </div>
  );
}

export default AssistanceModal;
