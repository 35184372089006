import React from "react";
import "./SelectPaiement.css";
const SelectPaiement = ({
  paiementMethod,
  setSelectedInput,
  selectedInput,
  className = "",
}) => {
  const borderClass =
    selectedInput === paiementMethod ? "b-1-blue" : "b-1-lightGrey";
  return (
    <div
      className={`selectPaiement py-4 px-3 br6 transition02 w49 wm100 ${borderClass} ${className}`}
      onClick={() => setSelectedInput(paiementMethod)}
    >
      <div className="d-flex align-items-center mb-1">
        <input
          type="radio"
          name="selectPaiement"
          className={`${paiementMethod} me-2`}
          onChange={() => {
            setSelectedInput(paiementMethod);
          }}
          checked={selectedInput === paiementMethod}
        />
        <h5 className="f16 m-0 bold text-blue">{paiementMethod}</h5>
      </div>
      <p className="f15 text-dark medium m-0">
        {paiementMethod === "Paiement ponctuel"
          ? "Déclenchez vous-même le paiement de votre solde chaque mois "
          : "L'ensemble de vos charge seront prélevées sur votre compte Vous n'avez rien à faire"}
      </p>
    </div>
  );
};

export default SelectPaiement;
