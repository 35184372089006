import React from "react";
import DocumentsDashboard from "./Documents/DocumentsDashboard";
import ReportsDashboard from "./Reports/ReportsDashboard";
import "./BriefingSection.css";
const BriefingSection = ({ evenementsData }) => {
  return (
    <div className="briefingSection w-100 d-flex align-items-center justify-content-between my-3">
      <ReportsDashboard evenementsData={evenementsData} />
      <DocumentsDashboard />
    </div>
  );
};

export default BriefingSection;
