import React from "react";
import { useNavigate } from "react-router-dom";
import useResponsive from "../../../hooks/useResponsive";
import CustomButton from "../../Common/CustomButton/CustomButton";

import "./BreadCrumbHeader.css";
function BreadCrumbHeader({ title, subtitle, withGoBack }) {
  const isMobileSize = useResponsive();
  const withArrow = isMobileSize && title !== "Tableau de bord";
  const headerClass = withArrow
    ? "text-darkGrey f25 regular"
    : "text-yellow f30 sBold";
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(-1);
  };
  return (
    <div className="breadCrumbHeaderContainer d-flex align-items-center justify-content-lg-between">
      {withArrow ? (
        <button
          className="border-0 bg-transparent pb-2 pe-2"
          onClick={handleNavigate}
        >
          <i className="bi bi-arrow-left text-blue f25"></i>
        </button>
      ) : null}

      <h1
        className={`breadCrumbHeader mb-4 mt-2 d-flex ps-2 ps-lg-0 ${headerClass}`}
      >
        {title}
        {subtitle ? (
          <span className="subtitle d-flex align-items-center f18 medium text-blue">
            <i className="bi bi-chevron-right mx-2 mt-1 f12 text-shadowBlue"></i>
            {subtitle}
          </span>
        ) : null}
      </h1>
      {withGoBack && !isMobileSize ? (
        <CustomButton
          borderColor="blue"
          text="Retour"
          variant="blue"
          callBack={handleNavigate}
          className="mb-4"
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default BreadCrumbHeader;
