import React, { useState } from "react";

import TabContentContainer from "../Components/Pages/MesComptes/TabContentContainer/TabContentContainer";

import BreadCrumbHeader from "../Components/Common/BreadCrumbHeader/BreadCrumbHeader";
import TabsNavbar from "../Components/Common/TabsNavbar/TabsNavbar";
import SideBar from "../Components/Common/SideBar/SideBar";
import InformationCard from "../Components/Common/InformationCard/informationCard";
import ContactCard from "../Components/Common/CardWrapper/ContactCard";

import { comptablesData } from "../helpers/comptablesCardFakeData";
import useResponsive from "../hooks/useResponsive";
import ViewHeader from "../Components/Common/ViewHeader/ViewHeader";

const MesComptesView = () => {
  const [activeTab, setActiveTab] = useState("Extrait de compte");
  const isMobileSize = useResponsive();
  const leftPaddingClass = activeTab === "Budget" ? "" : "ps-2";
  return (
    <div className="mesComptesView mb-5">
      <ViewHeader>
        <BreadCrumbHeader
          title={isMobileSize ? "Comptes" : "Mes Comptes"}
          subtitle={activeTab}
        />
        <TabsNavbar
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={["Extrait de compte", "Paiement", "Budget"]}
          withoutGap={isMobileSize ? true : false}
          className={isMobileSize ? "wm100 px-3 justify-content-between" : ""}
          tabMarginRight={isMobileSize ? "me-0" : "me-5"}
        />
      </ViewHeader>
      <div className="d-flex justify-content-between">
        <div
          className={`mesComptesMain w75 wm100 ${leftPaddingClass} pt-4 ps-lg-4 py-lg-5 `}
        >
          <TabContentContainer activeTab={activeTab} />
        </div>
        <SideBar>
          <InformationCard />
          <ContactCard title="Comptables" cardData={comptablesData} />
        </SideBar>
      </div>
    </div>
  );
};

export default MesComptesView;
