import React from "react";
import SideBar from "../Components/Common/SideBar/SideBar";
import IntroSection from "../Components/Pages/Dashboard/IntroSection/IntroSection";
import InformationCard from "../Components/Common/InformationCard/informationCard";
import CommonArticleCard from "../Components/Common/CardWrapper/ArticleCard";
import BriefingSection from "../Components/Pages/Dashboard/BriefingSection/BriefingSection";
import BreadCrumbHeader from "../Components/Common/BreadCrumbHeader/BreadCrumbHeader";
import useResponsive from "../hooks/useResponsive";
import NewsAgencyCard from "../Components/Pages/Dashboard/IntroSection/NewsAgencyCard/NewsAgencyCard";
import PropertyCardsSection from "../Components/Pages/Dashboard/PropertyCardsSection/PropertyCardsSection";
import { useSelector } from "react-redux";
import ViewHeader from "../Components/Common/ViewHeader/ViewHeader";
import useFetchDashboard from "../hooks/useFetchDashboard";
import ViewWrapper from "../Components/Common/ViewWrapper/ViewWrapper";

const DashboardView = () => {
  const isMobileSize = useResponsive();
  const user = useSelector((state) => state.usersReducer.user);

  const { data, loading, error } = useFetchDashboard(user.ID_PERSO);

  return (
    <ViewWrapper loading={loading} error={error}>
      <ViewHeader>
        <BreadCrumbHeader title="Tableau de bord" />
      </ViewHeader>
      <div className="w100 d-flex justify-content-between">
        <div className="w75 wm100 d-flex flex-column ">
          <IntroSection user={user} propertiesData={data.immeubles} />
          {isMobileSize ? (
            <NewsAgencyCard />
          ) : (
            <PropertyCardsSection propertiesData={data.immeubles} />
          )}
          <BriefingSection evenementsData={data.evenements} />
        </div>
        <SideBar>
          <InformationCard />
          <CommonArticleCard />
        </SideBar>
      </div>
    </ViewWrapper>
  );
};

export default DashboardView;
