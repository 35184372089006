import "./CardWrapper.css";
import { PolygoneSVG } from "../../Svgs";
const CardWrapper = ({ children, variantBg, width, hasFullHeight }) => {
  const widthClassName = width ? `w${width}` : "";
  const heightClassName = hasFullHeight ? `h100` : "";
  const bgClassName = variantBg ? `bg-${variantBg}` : "";
  return (
    <div
      className={`cardWrapper position-relative p-4 br5 wm100 ${bgClassName} ${widthClassName} ${heightClassName}`}
    >
      <div className="card-wrapper-content-container positon-relative zIndex-1">
        <div className="cardWrapper-plolygoneContainer position-absolute zIndex-n-1">
          <PolygoneSVG color={`${variantBg === "blue" ? "" : "#CCDFF23D"}`} />
        </div>
        {children}
      </div>
    </div>
  );
};

export default CardWrapper;
