import React from "react";

export const DashboardSvg = ({ color = "#2c2c2c" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.997"
      height="20"
      viewBox="0 0 19.997 20"
    >
      <path
        id="dashboard_icon"
        data-name="dashboard icon"
        d="M54,179a3.086,3.086,0,0,1-3.026-3.1V172.88A3.061,3.061,0,0,1,54,169.791h2.96A3.085,3.085,0,0,1,60,172.88V175.9a3.1,3.1,0,0,1-3.034,3.1Zm-1.4-6.121V175.9a1.432,1.432,0,0,0,.408,1.015,1.37,1.37,0,0,0,.983.421h2.981a1.37,1.37,0,0,0,.983-.421,1.428,1.428,0,0,0,.407-1.015V172.88a1.44,1.44,0,0,0-.41-1.011,1.38,1.38,0,0,0-.99-.421H54a1.418,1.418,0,0,0-1.4,1.433Zm-9.567,6.105A3.133,3.133,0,0,1,40,175.892V172.88a3.124,3.124,0,0,1,.889-2.187,2.992,2.992,0,0,1,2.145-.9h1.216a.81.81,0,0,1,.779.387.848.848,0,0,1,0,.884.811.811,0,0,1-.779.387H43.035a1.425,1.425,0,0,0-1.41,1.43v2.984a1.454,1.454,0,0,0,1.41,1.43h2.979a1.382,1.382,0,0,0,1-.421,1.443,1.443,0,0,0,.414-1.015v-4.476a.842.842,0,0,1,.365-.856.8.8,0,0,1,.915.026.843.843,0,0,1,.316.876v4.462a3.066,3.066,0,0,1-3.034,3.1h0Zm12.747-10.746a.836.836,0,0,1,0-1.659h1.188a1.393,1.393,0,0,0,1-.432,1.459,1.459,0,0,0,.4-1.029V162.1a1.436,1.436,0,0,0-1.4-1.43H54a1.417,1.417,0,0,0-1.4,1.43v4.519a.833.833,0,0,1-.835.815.8.8,0,0,1-.569-.258.836.836,0,0,1-.215-.595V162.1a3.122,3.122,0,0,1,.883-2.19A3,3,0,0,1,54,159h2.96a3.133,3.133,0,0,1,3.025,3.1v3.013a3.123,3.123,0,0,1-.859,2.195,3,3,0,0,1-2.128.933ZM46,168.209H43.035A3.087,3.087,0,0,1,40,165.12V162.1a3.1,3.1,0,0,1,3.034-3.1H46a3.084,3.084,0,0,1,3.03,3.1v3.022a3.1,3.1,0,0,1-.889,2.187A2.976,2.976,0,0,1,46,168.209Zm-3.974-7.133a1.454,1.454,0,0,0-.421,1.021v3.022a1.451,1.451,0,0,0,.41,1.04,1.389,1.389,0,0,0,.988.421h3a1.387,1.387,0,0,0,1-.432,1.449,1.449,0,0,0,.4-1.028V162.1a1.432,1.432,0,0,0-1.4-1.43H43.008A1.38,1.38,0,0,0,42.026,161.076Z"
        transform="translate(-40.001 -159)"
        fill={color}
      />
    </svg>
  );
};

export const BienSvg = ({ color = "#2c2c2c" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="22.999"
      viewBox="0 0 23 22.999"
    >
      <g id="Layer_46" data-name="Layer 46" transform="translate(-48 -48.004)">
        <path
          id="Tracé_95"
          data-name="Tracé 95"
          d="M144,176h1.769v1.769H144Z"
          transform="translate(-90.692 -120.919)"
          fill={color}
        />
        <path
          id="Tracé_96"
          data-name="Tracé 96"
          d="M144,240h1.769v1.769H144Z"
          transform="translate(-90.692 -181.381)"
          fill={color}
        />
        <path
          id="Tracé_97"
          data-name="Tracé 97"
          d="M144,304h1.769v1.769H144Z"
          transform="translate(-90.692 -241.843)"
          fill={color}
        />
        <path
          id="Tracé_98"
          data-name="Tracé 98"
          d="M208,176h1.769v1.769H208Z"
          transform="translate(-151.154 -120.919)"
          fill={color}
        />
        <path
          id="Tracé_99"
          data-name="Tracé 99"
          d="M208,240h1.769v1.769H208Z"
          transform="translate(-151.154 -181.381)"
          fill={color}
        />
        <path
          id="Tracé_100"
          data-name="Tracé 100"
          d="M208,304h1.769v1.769H208Z"
          transform="translate(-151.154 -241.843)"
          fill={color}
        />
        <path
          id="Tracé_101"
          data-name="Tracé 101"
          d="M272,176h1.769v1.769H272Z"
          transform="translate(-211.616 -120.919)"
          fill={color}
        />
        <path
          id="Tracé_102"
          data-name="Tracé 102"
          d="M272,240h1.769v1.769H272Z"
          transform="translate(-211.616 -181.381)"
          fill={color}
        />
        <path
          id="Tracé_103"
          data-name="Tracé 103"
          d="M272,304h1.769v1.769H272Z"
          transform="translate(-211.616 -241.843)"
          fill={color}
        />
        <path
          id="Tracé_104"
          data-name="Tracé 104"
          d="M70.115,69.234H69.23V54.2a.885.885,0,0,0-.489-.791L65.692,51.88V48.888a.885.885,0,0,0-1.1-.858L50.44,51.569a.885.885,0,0,0-.67.858V66.58a.885.885,0,1,0,1.769,0V53.117l12.384-3.1V69.234H60.384V66.58A.885.885,0,0,0,59.5,65.7H55.961a.885.885,0,0,0-.885.885v2.654H48.885a.885.885,0,0,0,0,1.769h21.23a.885.885,0,1,0,0-1.769ZM65.692,53.858l1.769.885V69.234H65.692ZM56.846,67.465h1.769v1.769H56.846Z"
          transform="translate(0 0)"
          fill={color}
        />
      </g>
    </svg>
  );
};

export const SignalementsSvg = ({ color = "#2c2c2c" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.333"
      height="18.472"
      viewBox="0 0 23.333 18.472"
    >
      <g
        id="Groupe_1334"
        data-name="Groupe 1334"
        transform="translate(0 -63.997)"
      >
        <path
          id="Tracé_279"
          data-name="Tracé 279"
          fill={color}
          d="M17.472,64.05,5.45,68.858H2.187A2.191,2.191,0,0,0,0,71.046v2.673a1.986,1.986,0,0,0,2.187,1.944h.826L3.9,81.843a.729.729,0,0,0,.721.626H7.534a.729.729,0,0,0,.725-.811l-.574-5.1,9.785,3.914a.729.729,0,0,0,1-.677V64.726a.728.728,0,0,0-1-.677ZM1.458,73.719V71.046a.731.731,0,0,1,.729-.729H4.861v3.889H2.187C1.855,74.205,1.458,74.121,1.458,73.719Zm5.261,7.291H5.25l-.763-5.347H5.45l.7.28Zm10.295-2.291L7.12,74.761a.727.727,0,0,0-.127-.052l-.674-.269V70.081L17.013,65.8Z"
        />
        <path
          id="Tracé_280"
          data-name="Tracé 280"
          d="M427.844,131.259l2.43-1.944a.73.73,0,1,0-.912-1.139l-2.431,1.944a.73.73,0,0,0,.912,1.139Z"
          fill={color}
          transform="translate(-407.215 -61.102)"
        />
        <path
          id="Tracé_281"
          data-name="Tracé 281"
          d="M427.844,309.508a.73.73,0,0,0-.912,1.139l2.431,1.944a.73.73,0,1,0,.912-1.139Z"
          fill={color}
          transform="translate(-407.215 -234.171)"
        />
        <path
          id="Tracé_282"
          data-name="Tracé 282"
          d="M429.827,240H427.4a.729.729,0,0,0,0,1.458h2.43a.729.729,0,1,0,0-1.458Z"
          fill={color}
          transform="translate(-407.223 -167.982)"
        />
      </g>
    </svg>
  );
};

export const ComptesSvg = ({ color = "#2c2c2c" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.999"
      height="20"
      viewBox="0 0 19.999 20"
    >
      <path
        id="Campaigns_icon"
        data-name="Campaigns icon"
        d="M46.253,229C42.392,229,40,226.608,40,222.756v-7.5c0-3.864,2.392-6.256,6.253-6.256h7.493c3.844,0,6.254,2.392,6.254,6.253v2.322a.764.764,0,0,1-.763.765h-.011a.756.756,0,0,1-.756-.756c0-.006,0-.012,0-.018v-2.313c0-3.043-1.68-4.723-4.723-4.723H46.253c-3.053,0-4.723,1.68-4.723,4.723v7.5c0,3.043,1.68,4.714,4.723,4.714h7.493c3.052,0,4.723-1.68,4.723-4.714a.765.765,0,0,1,1.53,0c0,3.855-2.391,6.247-6.243,6.247Zm-.553-4.521a.765.765,0,0,1-.738-.791v-6.4a.757.757,0,0,1,.782-.732h.009a.766.766,0,0,1,.739.792v6.391a.765.765,0,0,1-.765.739Zm3.57-.779v-9.379a.765.765,0,0,1,1.53,0h0V223.7a.765.765,0,1,1-1.53,0Zm4.239-.009V220.7a.765.765,0,1,1,1.531,0v2.991a.765.765,0,1,1-1.531,0Zm-4.239-9.414v0Z"
        transform="translate(-40 -208.999)"
        fill={color}
      />
    </svg>
  );
};
export const DocumentsSvg = ({ color = "#2c2c2c" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.97"
      height="20"
      viewBox="0 0 16.97 20"
    >
      <path
        id="Statement"
        d="M46.565,279A4.579,4.579,0,0,1,42,274.4q0-.049,0-.1v-2.523a.747.747,0,1,1,1.493,0v2.523a3.1,3.1,0,0,0,3.011,3.181h8.006a3.159,3.159,0,0,0,2.964-3.182v-8.925l-3.809-4.014v1.316a1.829,1.829,0,0,0,1.809,1.837.755.755,0,0,1,0,1.51h0a3.334,3.334,0,0,1-3.3-3.348v-2.162H46.565a3.075,3.075,0,0,0-3.068,3v4.159a.747.747,0,1,1-1.493,0v-4.159a4.6,4.6,0,0,1,4.562-4.51h6.3a.449.449,0,0,1,.057-.012c.02,0,.037.01.057.012h.2a.743.743,0,0,1,.539.233l5.04,5.312a.761.761,0,0,1,.211.523v9.228A4.64,4.64,0,0,1,54.514,279Zm.8-5.594a.755.755,0,0,1,0-1.51h5.371a.755.755,0,0,1,0,1.51Zm0-4.986a.755.755,0,0,1,0-1.51H50.7a.755.755,0,0,1,0,1.51Z"
        transform="translate(-42.002 -259)"
        fill={color}
      />
    </svg>
  );
};

export const AssembleeSvg = ({ color = "#2c2c2c" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.25"
      height="23.25"
      viewBox="0 0 23.25 23.25"
    >
      <path
        id="Tracé_270"
        data-name="Tracé 270"
        d="M23.243,19.75,21.836,9.669a.681.681,0,0,0-.675-.587h-.233A4.319,4.319,0,0,0,18.734,5.55a3.179,3.179,0,1,0-4.228,0,4.319,4.319,0,0,0-2.194,3.532H10.938A4.319,4.319,0,0,0,8.744,5.55a3.179,3.179,0,1,0-4.228,0A4.319,4.319,0,0,0,2.322,9.082H2.089a.681.681,0,0,0-.675.587L.006,19.75a.681.681,0,0,0,.675.775H2.165a4.292,4.292,0,0,0-.3,1.589v.454a.681.681,0,0,0,.681.681H9.809a.681.681,0,0,0,.681-.681v-.454a4.291,4.291,0,0,0-.3-1.589h2.877a4.291,4.291,0,0,0-.3,1.589v.454a.681.681,0,0,0,.681.681h7.266a.681.681,0,0,0,.681-.681v-.454a4.292,4.292,0,0,0-.3-1.589h1.484a.681.681,0,0,0,.675-.775ZM14.8,3.179A1.816,1.816,0,1,1,16.62,5,1.818,1.818,0,0,1,14.8,3.179ZM16.62,6.357a2.956,2.956,0,0,1,2.943,2.725H13.677A2.956,2.956,0,0,1,16.62,6.357Zm-9.99-5A1.816,1.816,0,1,1,4.813,3.179,1.818,1.818,0,0,1,6.63,1.362Zm0,5A2.956,2.956,0,0,1,9.573,9.082H3.687A2.956,2.956,0,0,1,6.63,6.357Zm-3.4,15.53a2.937,2.937,0,0,1,.635-1.61l0,0a2.947,2.947,0,0,1,4.612,0l0,0a2.936,2.936,0,0,1,.635,1.61ZM6.176,17.8a1.816,1.816,0,1,1,1.816-1.816A1.818,1.818,0,0,1,6.176,17.8Zm7.955,4.087a2.937,2.937,0,0,1,.635-1.61l0,0a2.947,2.947,0,0,1,4.612,0l0,0a2.937,2.937,0,0,1,.635,1.61Zm1.127-5.9A1.816,1.816,0,1,1,17.074,17.8,1.818,1.818,0,0,1,15.258,15.984Zm4.959,3.179a4.342,4.342,0,0,0-1.029-.808,3.179,3.179,0,1,0-4.228,0,4.342,4.342,0,0,0-1.029.808H9.319a4.341,4.341,0,0,0-1.029-.808,3.179,3.179,0,1,0-4.228,0,4.342,4.342,0,0,0-1.029.808H1.464l1.217-8.719H20.568l1.218,8.719Z"
        transform="translate(0)"
        fill={color}
      />
    </svg>
  );
};
export const DeconnexionSvg = ({ color = "#2C659D" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.5"
      height="18.276"
      viewBox="0 0 19.5 18.276"
    >
      <g
        id="Groupe_1207"
        data-name="Groupe 1207"
        transform="translate(368.29 -76.141)"
      >
        <path
          id="Tracé_267"
          data-name="Tracé 267"
          d="M-352.661,90.6a.778.778,0,0,1,.006,1.1,9.072,9.072,0,0,1-6.5,2.713,9.149,9.149,0,0,1-9.138-9.138,9.149,9.149,0,0,1,9.138-9.138,9.073,9.073,0,0,1,6.5,2.713.778.778,0,0,1-.006,1.1.778.778,0,0,1-1.1-.006,7.527,7.527,0,0,0-5.39-2.251,7.59,7.59,0,0,0-7.581,7.581,7.59,7.59,0,0,0,7.581,7.581,7.527,7.527,0,0,0,5.39-2.251A.779.779,0,0,1-352.661,90.6Z"
          fill={color}
        />
        <path
          id="Tracé_268"
          data-name="Tracé 268"
          d="M-246.209,163.157a.778.778,0,0,0-1.1,0,.779.779,0,0,0,0,1.1l1.4,1.4h-8.742a.779.779,0,0,0-.778.778.779.779,0,0,0,.778.779h8.742l-1.4,1.4a.779.779,0,0,0,0,1.1.776.776,0,0,0,.55.228.776.776,0,0,0,.55-.228l2.729-2.729a.779.779,0,0,0,.228-.55.778.778,0,0,0-.228-.55Z"
          transform="translate(-105.538 -81.157)"
          fill={color}
        />
      </g>
    </svg>
  );
};
export const HeaderLogoSvg = ({ isMobileSize }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={isMobileSize ? "141" : "163"}
      height={isMobileSize ? "38" : "44.084"}
      viewBox="0 0 163 44.084"
    >
      <g
        id="Groupe_6"
        data-name="Groupe 6"
        transform="translate(-43.614 -117.498)"
      >
        <g
          id="Groupe_1"
          data-name="Groupe 1"
          transform="translate(97.384 157.306)"
        >
          <path
            id="Tracé_1"
            data-name="Tracé 1"
            d="M185.955,225.806h.856v.489h-.832v1.028h-.392V224.8h1.37v.489h-.979v.514Z"
            transform="translate(-185.588 -223.971)"
            fill="#fff"
          />
          <path
            id="Tracé_2"
            data-name="Tracé 2"
            d="M192.048,225.93c0-.734.44-1.321.979-1.321s.978.587.978,1.321-.44,1.321-.978,1.321A1.256,1.256,0,0,1,192.048,225.93Zm.391,0c0,.44.245.832.587.832s.587-.391.587-.832-.245-.783-.587-.783C192.684,225.074,192.439,225.49,192.439,225.93Z"
            transform="translate(-189.601 -223.851)"
            fill="#fff"
          />
          <path
            id="Tracé_3"
            data-name="Tracé 3"
            d="M200.9,227.033h-.391v-2.618l1.223,1.541v-1.493h.392v2.618l-1.223-1.541Z"
            transform="translate(-194.859 -223.73)"
            fill="#fff"
          />
          <path
            id="Tracé_4"
            data-name="Tracé 4"
            d="M209.046,227.122c-.538,0-.979-.587-.979-1.321s.44-1.321.979-1.321a.984.984,0,0,1,.832.685l-.342.2a.65.65,0,0,0-.489-.391c-.342,0-.587.391-.587.783,0,.44.245.832.587.832a.65.65,0,0,0,.489-.392l.342.2A.869.869,0,0,1,209.046,227.122Z"
            transform="translate(-199.554 -223.771)"
            fill="#fff"
          />
          <path
            id="Tracé_5"
            data-name="Tracé 5"
            d="M216.141,227.194v-2.52h.392v2.52Z"
            transform="translate(-204.57 -223.891)"
            fill="#fff"
          />
          <path
            id="Tracé_6"
            data-name="Tracé 6"
            d="M221.248,224.442h.881v.489h-.881v.538h.979v.489h-1.37v-2.52h1.37v.489h-.979Zm.2-1.248-.392-.392.343-.2.342.538h-.294Z"
            transform="translate(-207.5 -222.607)"
            fill="#fff"
          />
          <path
            id="Tracé_7"
            data-name="Tracé 7"
            d="M227.9,227.194v-2.52h.783c.392,0,.636.342.636.881a.917.917,0,0,1-.342.783c.147.294.342.587.44.832h-.44c-.147-.245-.294-.489-.44-.783h-.245v.783H227.9Zm.783-1.223c.1,0,.245-.147.245-.391,0-.147-.049-.392-.245-.392h-.391v.783Z"
            transform="translate(-211.873 -223.891)"
            fill="#fff"
          />
          <path
            id="Tracé_8"
            data-name="Tracé 8"
            d="M235.459,225.806h.881v.489h-.881v.538h.979v.489h-1.37V224.8h1.37v.489h-.979Z"
            transform="translate(-216.328 -223.971)"
            fill="#fff"
          />
          <path
            id="Tracé_9"
            data-name="Tracé 9"
            d="M246.763,227.033h-.392v-2.618l1.223,1.541v-1.493h.391v2.618l-1.223-1.541Z"
            transform="translate(-223.351 -223.73)"
            fill="#fff"
          />
          <path
            id="Tracé_10"
            data-name="Tracé 10"
            d="M254.445,227.194v-2.52h.392v2.52Z"
            transform="translate(-228.367 -223.891)"
            fill="#fff"
          />
          <path
            id="Tracé_11"
            data-name="Tracé 11"
            d="M259.752,224.544a.984.984,0,0,1,.832.685l-.342.2a.65.65,0,0,0-.489-.392c-.342,0-.587.392-.587.783,0,.44.245.832.587.832a.65.65,0,0,0,.489-.392l.342.2a1,1,0,0,1-.783.685l-.049.1h.1a.3.3,0,0,1,.147.294c0,.294-.2.294-.294.294a.374.374,0,0,1-.245-.1l.049-.147a.18.18,0,0,0,.147.049.105.105,0,0,0,.1-.1c0-.049-.049-.049-.1-.049a.171.171,0,0,0-.1.049l-.049-.1.1-.294a1.219,1.219,0,0,1-.832-1.272C258.773,225.131,259.213,224.544,259.752,224.544Z"
            transform="translate(-231.056 -223.81)"
            fill="#fff"
          />
          <path
            id="Tracé_12"
            data-name="Tracé 12"
            d="M266.589,225.93c0-.734.44-1.321.979-1.321s.979.587.979,1.321-.44,1.321-.979,1.321A1.215,1.215,0,0,1,266.589,225.93Zm.342,0c0,.44.245.832.587.832s.587-.391.587-.832-.245-.783-.587-.783C267.225,225.074,266.931,225.49,266.931,225.93Z"
            transform="translate(-235.911 -223.851)"
            fill="#fff"
          />
          <path
            id="Tracé_13"
            data-name="Tracé 13"
            d="M275.051,227.194v-2.52h.391v2.52Z"
            transform="translate(-241.169 -223.891)"
            fill="#fff"
          />
          <path
            id="Tracé_14"
            data-name="Tracé 14"
            d="M280.211,226.713a.327.327,0,0,0,.342-.294c0-.2-.2-.245-.391-.294a.755.755,0,0,1-.734-.783.664.664,0,0,1,.734-.734.935.935,0,0,1,.685.342l-.2.392a.779.779,0,0,0-.489-.245c-.1,0-.293.049-.293.245s.2.2.342.245c.294.1.734.147.734.832a.685.685,0,0,1-.734.783,1.123,1.123,0,0,1-.832-.392l.2-.391A1.247,1.247,0,0,0,280.211,226.713Z"
            transform="translate(-243.858 -223.851)"
            fill="#fff"
          />
          <path
            id="Tracé_15"
            data-name="Tracé 15"
            d="M287.134,225.806h.881v.489h-.881v.538h.979v.489h-1.37V224.8h1.37v.489h-.979Z"
            transform="translate(-248.432 -223.971)"
            fill="#fff"
          />
          <path
            id="Tracé_16"
            data-name="Tracé 16"
            d="M298.5,225.806h.881v.489H298.5v.538h.979v.489h-1.37V224.8h1.37v.489H298.5Z"
            transform="translate(-255.495 -223.971)"
            fill="#fff"
          />
          <path
            id="Tracé_17"
            data-name="Tracé 17"
            d="M305.564,225.163v2.079h-.391v-2.079h-.538v-.489h1.419v.489Z"
            transform="translate(-259.548 -223.891)"
            fill="#fff"
          />
          <path
            id="Tracé_18"
            data-name="Tracé 18"
            d="M315.939,224.674h.734a.788.788,0,0,1,.636.294,1.41,1.41,0,0,1,.293.929,1.324,1.324,0,0,1-.293.93.788.788,0,0,1-.636.294h-.734Zm.734,2.079c.245,0,.538-.147.538-.783s-.294-.783-.538-.783h-.343v1.59l.343-.025Z"
            transform="translate(-266.571 -223.891)"
            fill="#fff"
          />
          <path
            id="Tracé_19"
            data-name="Tracé 19"
            d="M323.888,225.806h.88v.489h-.88v.538h.979v.489H323.5V224.8h1.37v.489h-.979Z"
            transform="translate(-271.266 -223.971)"
            fill="#fff"
          />
          <path
            id="Tracé_20"
            data-name="Tracé 20"
            d="M336.333,225.579c0,.489-.244.881-.734.881h-.343v.734h-.391v-2.52h.734C336.088,224.674,336.333,225.065,336.333,225.579Zm-.391,0c0-.2-.1-.44-.294-.44h-.342v.832h.391C335.844,226.019,335.941,225.824,335.941,225.579Z"
            transform="translate(-278.329 -223.891)"
            fill="#fff"
          />
          <path
            id="Tracé_21"
            data-name="Tracé 21"
            d="M341.906,227.194v-2.52h.783c.391,0,.636.342.636.881a.917.917,0,0,1-.342.783c.147.294.342.587.44.832h-.44c-.147-.245-.293-.489-.44-.783H342.3v.783h-.391Zm.783-1.223c.1,0,.245-.147.245-.391,0-.147-.049-.392-.245-.392H342.3v.783Z"
            transform="translate(-282.704 -223.891)"
            fill="#fff"
          />
          <path
            id="Tracé_22"
            data-name="Tracé 22"
            d="M348.753,225.93c0-.734.44-1.321.978-1.321s.979.587.979,1.321-.44,1.321-.979,1.321A1.279,1.279,0,0,1,348.753,225.93Zm.416,0c0,.44.245.832.587.832s.587-.391.587-.832-.245-.783-.587-.783C349.414,225.074,349.169,225.49,349.169,225.93Z"
            transform="translate(-286.958 -223.851)"
            fill="#fff"
          />
          <path
            id="Tracé_23"
            data-name="Tracé 23"
            d="M357.256,227.372l-.881-2.569h.44l.489,1.37.489-1.37h.44Z"
            transform="translate(-291.693 -223.971)"
            fill="#fff"
          />
          <path
            id="Tracé_24"
            data-name="Tracé 24"
            d="M364.582,225.806h.881v.489h-.881v.538h.979v.489h-1.37V224.8h1.37v.489h-.979Z"
            transform="translate(-296.548 -223.971)"
            fill="#fff"
          />
          <path
            id="Tracé_25"
            data-name="Tracé 25"
            d="M371.558,227.033h-.391v-2.618l1.223,1.541v-1.493h.392v2.618l-1.223-1.541Z"
            transform="translate(-300.883 -223.73)"
            fill="#fff"
          />
          <path
            id="Tracé_26"
            data-name="Tracé 26"
            d="M379.767,227.122c-.538,0-.978-.587-.978-1.321s.44-1.321.978-1.321a.984.984,0,0,1,.832.685l-.343.2a.65.65,0,0,0-.489-.391c-.294,0-.587.391-.587.783,0,.44.245.832.587.832a.65.65,0,0,0,.489-.392l.343.2C380.4,226.877,380.11,227.122,379.767,227.122Z"
            transform="translate(-305.618 -223.771)"
            fill="#fff"
          />
          <path
            id="Tracé_27"
            data-name="Tracé 27"
            d="M387.061,225.806h.881v.489h-.881v.538h.978v.489h-1.37V224.8h1.37v.489h-.978Z"
            transform="translate(-310.514 -223.971)"
            fill="#fff"
          />
          <path
            id="Tracé_28"
            data-name="Tracé 28"
            d="M397.908,228.586V228.1h.783v.489Z"
            transform="translate(-317.496 -226.018)"
            fill="#fff"
          />
          <path
            id="Tracé_29"
            data-name="Tracé 29"
            d="M408.172,224.415l.979,2.618h-.44l-.147-.392h-.832l-.147.392h-.44Zm.2,1.786-.245-.636-.245.636Z"
            transform="translate(-323.235 -223.73)"
            fill="#fff"
          />
          <path
            id="Tracé_30"
            data-name="Tracé 30"
            d="M416.8,225.9v.147a1.034,1.034,0,0,1-.93,1.076c-.538,0-.978-.587-.978-1.321s.44-1.321.978-1.321a1.014,1.014,0,0,1,.832.587l-.343.294a.649.649,0,0,0-.489-.391c-.294,0-.587.391-.587.783,0,.44.245.832.587.832a.57.57,0,0,0,.538-.489h-.685v-.489H416.8Z"
            transform="translate(-328.051 -223.771)"
            fill="#fff"
          />
          <path
            id="Tracé_31"
            data-name="Tracé 31"
            d="M423.556,225.806h.881v.489h-.881v.538h.979v.489h-1.37V224.8h1.37v.489h-.979Z"
            transform="translate(-333.187 -223.971)"
            fill="#fff"
          />
          <path
            id="Tracé_32"
            data-name="Tracé 32"
            d="M430.6,227.033h-.392v-2.618l1.223,1.541v-1.493h.392v2.618l-1.223-1.541Z"
            transform="translate(-337.561 -223.73)"
            fill="#fff"
          />
          <path
            id="Tracé_33"
            data-name="Tracé 33"
            d="M438.742,227.122c-.538,0-.979-.587-.979-1.321s.44-1.321.979-1.321a.984.984,0,0,1,.832.685l-.343.2a.65.65,0,0,0-.489-.391c-.294,0-.587.391-.587.783,0,.44.245.832.587.832a.65.65,0,0,0,.489-.392l.343.2A.889.889,0,0,1,438.742,227.122Z"
            transform="translate(-342.257 -223.771)"
            fill="#fff"
          />
          <path
            id="Tracé_34"
            data-name="Tracé 34"
            d="M446.1,225.806h.881v.489H446.1v.538h.979v.489h-1.37V224.8h1.37v.489H446.1Z"
            transform="translate(-347.193 -223.971)"
            fill="#fff"
          />
          <path
            id="Tracé_35"
            data-name="Tracé 35"
            d="M457.779,226.713a.327.327,0,0,0,.343-.294c0-.2-.2-.245-.392-.294a.755.755,0,0,1-.734-.783.664.664,0,0,1,.734-.734.936.936,0,0,1,.685.342l-.2.392a.777.777,0,0,0-.489-.245c-.1,0-.293.049-.293.245s.2.2.342.245c.294.1.734.147.734.832a.685.685,0,0,1-.734.783,1.125,1.125,0,0,1-.832-.392l.2-.391A.97.97,0,0,0,457.779,226.713Z"
            transform="translate(-354.175 -223.851)"
            fill="#fff"
          />
          <path
            id="Tracé_36"
            data-name="Tracé 36"
            d="M464.311,227.194v-2.52h.391v2.52Z"
            transform="translate(-358.75 -223.891)"
            fill="#fff"
          />
          <path
            id="Tracé_37"
            data-name="Tracé 37"
            d="M470.95,227.033h-.392v-1.174l-.538.881-.538-.881v1.174h-.391v-2.618l.929,1.492.93-1.492v2.618Z"
            transform="translate(-361.72 -223.73)"
            fill="#fff"
          />
        </g>
        <g
          id="Groupe_3"
          data-name="Groupe 3"
          transform="translate(97.384 131.442)"
        >
          <path
            id="Tracé_38"
            data-name="Tracé 38"
            d="M195.638,159.7v16.087h-4.285V159.661h-5.765v-3.7h15.893v3.7h-5.843Z"
            transform="translate(-185.588 -155.338)"
            fill="#fff"
          />
          <g id="Groupe_2" data-name="Groupe 2" transform="translate(12.971)">
            <path
              id="Tracé_39"
              data-name="Tracé 39"
              d="M241.611,174.765,230.7,154.315l-10.868,20.45h5.025l5.843-11.88,5.882,11.88h5.025Z"
              transform="translate(-219.837 -154.315)"
              fill="#fff"
            />
          </g>
          <path
            id="Tracé_40"
            data-name="Tracé 40"
            d="M295.629,165.529a5.056,5.056,0,0,1,3.311,4.635c0,4.44-3.389,5.765-6.972,5.765H283.4V156.063h8.257c3.857,0,6.466,1.4,6.466,5.492A4.433,4.433,0,0,1,295.629,165.529Zm-3.9-1.636a2.02,2.02,0,0,0,2.064-2.181,2.064,2.064,0,0,0-2.064-1.987h-4.012v4.168Zm2.883,6c0-1.208-.935-2.259-2.766-2.259h-4.168v4.479h4.246C293.72,172.15,294.616,171.06,294.616,169.891Z"
            transform="translate(-246.354 -155.401)"
            fill="#fff"
          />
          <path
            id="Tracé_41"
            data-name="Tracé 41"
            d="M331.119,165.215A10.7,10.7,0,1,1,341.792,175.5,10.487,10.487,0,0,1,331.119,165.215Zm4.246,0a6.427,6.427,0,1,0,12.854,0,6.428,6.428,0,0,0-12.854,0Z"
            transform="translate(-276.002 -154.698)"
            fill="#fff"
          />
          <path
            id="Tracé_42"
            data-name="Tracé 42"
            d="M401.291,175.641h-4.246V155.035l13.672,11.8v-11.8H415v20.606l-13.75-11.608v11.608Z"
            transform="translate(-316.96 -154.762)"
            fill="#fff"
          />
          <path
            id="Tracé_43"
            data-name="Tracé 43"
            d="M457.52,175.724V155.858H461.8v19.866Z"
            transform="translate(-354.531 -155.274)"
            fill="#fff"
          />
        </g>
        <g
          id="Groupe_4"
          data-name="Groupe 4"
          transform="translate(97.384 122.093)"
        >
          <path
            id="Tracé_44"
            data-name="Tracé 44"
            d="M188.042,134.589a2.4,2.4,0,0,1-2.454-2.376,2.459,2.459,0,0,1,4.6-1.13l-.935.351a1.527,1.527,0,0,0-1.246-.74,1.5,1.5,0,0,0-1.48,1.48,1.479,1.479,0,0,0,1.48,1.48,1.528,1.528,0,0,0,1.246-.74l.935.351A2.423,2.423,0,0,1,188.042,134.589Z"
            transform="translate(-185.588 -129.759)"
            fill="#fff"
          />
          <path
            id="Tracé_45"
            data-name="Tracé 45"
            d="M216.673,129.734l2.532,4.713h-1.091l-.351-.7h-2.142l-.351.7H214.18Zm.623,3.155-.584-1.207-.584,1.207Z"
            transform="translate(-203.351 -129.695)"
            fill="#fff"
          />
          <path
            id="Tracé_46"
            data-name="Tracé 46"
            d="M248.148,132.16a1.178,1.178,0,0,1,.779,1.052c0,1.013-.779,1.324-1.6,1.324h-1.986v-4.6h1.869c.857,0,1.48.312,1.48,1.285A.932.932,0,0,1,248.148,132.16Zm-.935-.35a.457.457,0,0,0,.467-.506.5.5,0,0,0-.467-.468h-.935v.974Zm.7,1.363c0-.273-.234-.506-.623-.506h-.974v1.052h.974A.555.555,0,0,0,247.915,133.173Z"
            transform="translate(-222.713 -129.823)"
            fill="#fff"
          />
          <path
            id="Tracé_47"
            data-name="Tracé 47"
            d="M273.73,134.536v-4.6h1.013v4.6Z"
            transform="translate(-240.348 -129.823)"
            fill="#fff"
          />
          <path
            id="Tracé_48"
            data-name="Tracé 48"
            d="M296.713,134.344h-.974v-4.713l3.155,2.8v-2.648h1.013V134.5l-3.155-2.766v2.61Z"
            transform="translate(-254.022 -129.631)"
            fill="#fff"
          />
          <path
            id="Tracé_49"
            data-name="Tracé 49"
            d="M326.784,132.015h2.181v.857h-2.181v.974h2.454v.857h-3.467v-4.557h3.467V131h-2.454Z"
            transform="translate(-272.68 -129.951)"
            fill="#fff"
          />
          <path
            id="Tracé_50"
            data-name="Tracé 50"
            d="M354.952,130.9v3.7h-1.013v-3.7h-1.324v-.857h3.662v.857Z"
            transform="translate(-289.357 -129.887)"
            fill="#fff"
          />
        </g>
        <g
          id="Groupe_5"
          data-name="Groupe 5"
          transform="translate(43.614 117.498)"
        >
          <path
            id="Tracé_51"
            data-name="Tracé 51"
            d="M85.3,198.461l-3.5-6.628a26.951,26.951,0,0,1-7.175,7.206,9.441,9.441,0,0,0,2.736-.243s.365,3.709-1.855,5.533a4.354,4.354,0,0,1-2.615.973,9.74,9.74,0,0,1-2.828-.274c-.334-2.067-.578-2.706-1.064-3.314-7.327,2.28-14.928.578-21.282-1.642-.73,1.307-1.368,2.523-1.916,3.618-1.368,2.523-2.189,4.074-2.189,4.074H90.252Z"
            transform="translate(-43.614 -163.68)"
            fill="#fff"
          />
          <path
            id="Tracé_52"
            data-name="Tracé 52"
            d="M146.33,190.629h0C146.421,190.751,146.391,190.72,146.33,190.629Z"
            transform="translate(-107.414 -162.932)"
            fill="#fff"
          />
          <path
            id="Tracé_53"
            data-name="Tracé 53"
            d="M76.728,117.5c0,.486-10.367,19.944-17.3,32.835a37.719,37.719,0,0,0,12.86-1.885,2.5,2.5,0,0,0-1.7-1.916c-.73-.243-1.216-.273-1.49-.365a4.464,4.464,0,0,1,2.22-.79,2.69,2.69,0,0,1,2.158-.639c2.372.183,2.979,1.4,3.588,1.307a27.552,27.552,0,0,0,2.219-1.794c1.095-1.156.973-1,1.095-1.156a22.117,22.117,0,0,0,4.743-9.85Z"
            transform="translate(-53.439 -117.498)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};

export const BuildingSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g id="Layer_50" data-name="Layer 50" transform="translate(-48 -48)">
        <path
          id="Tracé_123"
          data-name="Tracé 123"
          d="M208,112h2.308v2.308H208Z"
          transform="translate(-148.462 -59.385)"
          fill="#f3cd4b"
        />
        <path
          id="Tracé_124"
          data-name="Tracé 124"
          d="M208,176h2.308v2.308H208Z"
          transform="translate(-148.462 -118.769)"
          fill="#f3cd4b"
        />
        <path
          id="Tracé_125"
          data-name="Tracé 125"
          d="M208,240h2.308v2.308H208Z"
          transform="translate(-148.462 -178.154)"
          fill="#f3cd4b"
        />
        <path
          id="Tracé_126"
          data-name="Tracé 126"
          d="M208,304h2.308v2.308H208Z"
          transform="translate(-148.462 -237.538)"
          fill="#f3cd4b"
        />
        <path
          id="Tracé_127"
          data-name="Tracé 127"
          d="M272,112h2.308v2.308H272Z"
          transform="translate(-207.846 -59.385)"
          fill="#f3cd4b"
        />
        <path
          id="Tracé_128"
          data-name="Tracé 128"
          d="M272,176h2.308v2.308H272Z"
          transform="translate(-207.846 -118.769)"
          fill="#f3cd4b"
        />
        <path
          id="Tracé_129"
          data-name="Tracé 129"
          d="M272,240h2.308v2.308H272Z"
          transform="translate(-207.846 -178.154)"
          fill="#f3cd4b"
        />
        <path
          id="Tracé_130"
          data-name="Tracé 130"
          d="M272,304h2.308v2.308H272Z"
          transform="translate(-207.846 -237.538)"
          fill="#f3cd4b"
        />
        <path
          id="Tracé_131"
          data-name="Tracé 131"
          d="M76.846,75.692H71.077V49.154A1.154,1.154,0,0,0,69.923,48H56.077a1.154,1.154,0,0,0-1.154,1.154V72.231a1.154,1.154,0,0,0,2.308,0V50.308H68.769V75.692H66.462V72.231a1.154,1.154,0,0,0-1.154-1.154H60.692a1.154,1.154,0,0,0-1.154,1.154v3.462H49.154a1.154,1.154,0,0,0,0,2.308H76.846a1.154,1.154,0,0,0,0-2.308Zm-15-2.308h2.308v2.308H61.846Z"
          fill="#f3cd4b"
        />
      </g>
    </svg>
  );
};

export const FolderSVG = ({ color }) => {
  return (
    <svg
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 22 22"
      style={{ enableBackground: "new 0 0 22 22" }}
      xml="preserve"
      fill={color}
    >
      <g>
        <g id="_53" transform="translate(-0.435 0)">
          <path
            id="Vector"
            className="st0"
            d="M10.5,5.2C10.4,5.1,10.2,5,10.1,5H4.4C4.2,5,4,5.2,4,5.4c0,0,0,0,0,0v10.9c0,1,0.8,1.8,1.8,1.8
			h11.3c1,0,1.8-0.8,1.8-1.8V9.1c0-1-0.8-1.8-1.8-1.8h-5.5c-0.2,0-0.4-0.1-0.4-0.3L10.5,5.2z"
          />
        </g>
      </g>
    </svg>
  );
};

export const DetailsSvg = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Frame_2_Background_Mask_"
            data-name="Frame 2 (Background/Mask)"
            d="M0,0H25V25H0Z"
            fill="none"
            stroke="#707070"
            strokeWidth="1"
          />
        </clipPath>
      </defs>
      <g
        id="Groupe_de_masques_1"
        data-name="Groupe de masques 1"
        clipPath="url(#clip-path)"
      >
        <g
          id="Group_1689"
          data-name="Group 1689"
          transform="translate(3.125 10.417)"
        >
          <circle
            id="Ellipse_4"
            data-name="Ellipse 4"
            cx="1.823"
            cy="1.823"
            r="1.823"
            transform="translate(0 3.646) rotate(-90)"
            fill={color}
          />
          <circle
            id="Ellipse_5"
            data-name="Ellipse 5"
            cx="1.823"
            cy="1.823"
            r="1.823"
            transform="translate(7.292 3.646) rotate(-90)"
            fill={color}
          />
          <circle
            id="Ellipse_6"
            data-name="Ellipse 6"
            cx="1.823"
            cy="1.823"
            r="1.823"
            transform="translate(14.583 3.646) rotate(-90)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

export const AssistanceSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.5"
      height="20.5"
      viewBox="0 0 20.5 20.5"
    >
      <g id="Groupe_73" data-name="Groupe 73" transform="translate(-48 -48)">
        <path
          id="Tracé_105"
          data-name="Tracé 105"
          d="M67.712,48H48.788a.788.788,0,0,0-.788.788V64.558a.788.788,0,0,0,.788.788H54.77l2.923,2.923a.788.788,0,0,0,1.114,0h0l3.154-3.154A.788.788,0,0,0,60.846,64l-2.6,2.6-2.6-2.6a.788.788,0,0,0-.557-.231H49.577V49.577H66.923V63.769H64.558a.788.788,0,1,0,0,1.577h3.154a.788.788,0,0,0,.788-.788V48.788A.788.788,0,0,0,67.712,48Z"
          fill="#f3cd4b"
          fillRule="evenodd"
        />
        <path
          id="Tracé_106"
          data-name="Tracé 106"
          d="M212.731,146.358a2.365,2.365,0,1,0-4.731,0,.788.788,0,0,0,1.577,0,.788.788,0,0,1,1.577.011h0c0,.114-.055.5-.688.7a1.38,1.38,0,0,0-.889,1.285v1.162a.788.788,0,0,0,1.577,0v-1.017a2.31,2.31,0,0,0,1.577-2.137Z"
          transform="translate(-152.115 -91.262)"
          fill="#f3cd4b"
          fillRule="evenodd"
        />
        <path
          id="Tracé_107"
          data-name="Tracé 107"
          d="M240.771,288a.791.791,0,1,0,.793.792.791.791,0,0,0-.793-.792Z"
          transform="translate(-182.521 -228.173)"
          fill="#f3cd4b"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};
export const PolygoneSVG = ({
  color = "#fefefe",
  id = "",
  rotate = 68,
  translate = "72.32",
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104.911 109.884" id={id}>
    <path
      id="Polygone"
      data-name="Polygone 8"
      d="M43.5,0,87,78H0Z"
      transform={`translate(${translate}) rotate(${rotate})`}
      fill={color}
    />
  </svg>
);

export const EnCoursSVG = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27.916"
    height="27.914"
    viewBox="0 0 27.916 27.914"
  >
    <g
      id="Groupe_1488"
      data-name="Groupe 1488"
      transform="translate(-26.667 -26.705)"
    >
      <path
        id="Tracé_284"
        data-name="Tracé 284"
        d="M118.964,113.979a2.268,2.268,0,0,1-1.132-.3l-6.752-3.9a2.275,2.275,0,0,1-.831-3.1l.974-1.688a5.713,5.713,0,0,1,4.5-2.377.305.305,0,0,0,.234-.406,5.713,5.713,0,0,1-.19-5.086l.974-1.688a2.277,2.277,0,0,1,3.1-.832l6.752,3.9a2.275,2.275,0,0,1,.831,3.1l-.974,1.688a5.713,5.713,0,0,1-4.5,2.379.3.3,0,0,0-.234.405,5.713,5.713,0,0,1,.19,5.087l-.974,1.688A2.272,2.272,0,0,1,118.964,113.979Zm-.26-17.736a.325.325,0,0,0-.282.162l-.974,1.688a4.015,4.015,0,0,0,.3,3.356,2.255,2.255,0,0,1-1.788,3.1,4.016,4.016,0,0,0-3.055,1.419l-.974,1.688a.325.325,0,0,0,.118.444h0l6.752,3.9a.327.327,0,0,0,.443-.119l.974-1.688a4.015,4.015,0,0,0-.3-3.356,2.253,2.253,0,0,1,1.788-3.094,4.025,4.025,0,0,0,3.055-1.42l.974-1.688a.325.325,0,0,0-.118-.444h0l-6.752-3.9a.329.329,0,0,0-.161-.043Z"
        transform="translate(-78.209 -63.476)"
        fill={color}
      />
      <path
        id="Tracé_285"
        data-name="Tracé 285"
        d="M93.617,41.634a.974.974,0,0,1-.974-.974,12.01,12.01,0,0,0-20.5-8.493,12.233,12.233,0,0,0-1.119,1.289.974.974,0,1,1-1.558-1.169h0a14.583,14.583,0,0,1,1.3-1.493,13.958,13.958,0,0,1,23.83,9.865A.974.974,0,0,1,93.617,41.634Z"
        transform="translate(-40.008 0)"
        fill={color}
      />
      <path
        id="Tracé_286"
        data-name="Tracé 286"
        d="M40.625,254.932a13.955,13.955,0,0,1-13.958-13.953v-.005a.974.974,0,1,1,1.948,0,12.013,12.013,0,0,0,21.621,7.2.975.975,0,0,1,1.558,1.169,14.39,14.39,0,0,1-1.3,1.494,13.872,13.872,0,0,1-9.871,4.09Z"
        transform="translate(0 -200.313)"
        fill={color}
      />
      <path
        id="Tracé_287"
        data-name="Tracé 287"
        d="M365.308,366.3a.974.974,0,0,1-.974-.974v-2.7h-2.7a.974.974,0,1,1,0-1.948h3.673a.974.974,0,0,1,.974.974v3.672A.974.974,0,0,1,365.308,366.3Z"
        transform="translate(-313.666 -313.651)"
        fill={color}
      />
      <path
        id="Tracé_288"
        data-name="Tracé 288"
        d="M63.633,64.607H59.961a.974.974,0,0,1-.974-.974V59.961a.974.974,0,0,1,1.948,0v2.7h2.7a.974.974,0,1,1,0,1.948Z"
        transform="translate(-30.353 -30.317)"
        fill={color}
      />
    </g>
  </svg>
);

export const TraiteSVG = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28.5"
    height="28.5"
    viewBox="0 0 28.5 28.5"
  >
    <g
      id="Groupe_1345"
      data-name="Groupe 1345"
      transform="translate(-21.333 -21.333)"
    >
      <path
        id="Tracé_289"
        data-name="Tracé 289"
        d="M35.583,21.333a14.25,14.25,0,1,0,14.25,14.25A14.25,14.25,0,0,0,35.583,21.333Zm0,25.909A11.659,11.659,0,1,1,47.242,35.583,11.659,11.659,0,0,1,35.583,47.242Z"
        fill={color}
      />
      <path
        id="Tracé_290"
        data-name="Tracé 290"
        d="M157.983,181.583l-5.053,4.632L150.712,184a.955.955,0,0,0-1.373,1.326l.023.024,2.864,2.864a.955.955,0,0,0,1.32.029l5.727-5.25a.955.955,0,1,0-1.291-1.407Z"
        transform="translate(-117.935 -148.96)"
        fill={color}
      />
    </g>
  </svg>
);

export const ValideSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28.5"
    height="28.5"
    viewBox="0 0 28.5 28.5"
  >
    <g id="Groupe_1335" data-name="Groupe 1335" transform="translate(0 -0.168)">
      <circle
        id="Ellipse_38"
        data-name="Ellipse 38"
        cx="14.25"
        cy="14.25"
        r="14.25"
        transform="translate(0 0.168)"
        fill="#32bea6"
      />
      <path
        id="Tracé_283"
        data-name="Tracé 283"
        d="M121.133,148.934l-6.7-5.283,1.905-2.474,4.115,3.244,6.791-9.917,2.548,1.786Z"
        transform="translate(-108.021 -126.897)"
        fill="#fff"
      />
    </g>
  </svg>
);

export const ViewSVG = ({ color, height = 13, width = 15 }) => (
  <svg
    id="_44_Visibility"
    data-name=" 44 Visibility"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 15.475 10.257"
  >
    <path
      id="Tracé_291"
      data-name="Tracé 291"
      d="M179.621,181.7c-3.761,0-3.761-5.7,0-5.7C183.339,176,183.5,181.7,179.621,181.7Zm0-4.559a1.709,1.709,0,0,0,0,3.419A1.709,1.709,0,0,0,179.621,177.14Z"
      transform="translate(-171.883 -173.721)"
      fill={color}
    />
    <path
      id="Tracé_292"
      data-name="Tracé 292"
      d="M46.47,122.257a9.241,9.241,0,0,1-7.408-4.111,1.734,1.734,0,0,1,0-2.035A9.241,9.241,0,0,1,46.47,112a9.241,9.241,0,0,1,7.408,4.111,1.734,1.734,0,0,1,0,2.035A9.241,9.241,0,0,1,46.47,122.257Zm-6.486-4.779a8.021,8.021,0,0,0,6.486,3.639,8.021,8.021,0,0,0,6.486-3.639.6.6,0,0,0,0-.7,8.022,8.022,0,0,0-6.486-3.638,8.021,8.021,0,0,0-6.486,3.639.6.6,0,0,0,0,.7Z"
      transform="translate(-38.732 -112)"
      fill={color}
    />
  </svg>
);

export const VoteSVG = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="18"
    viewBox="0 0 16.852 14.481"
  >
    <path
      id="Tracé_317"
      data-name="Tracé 317"
      d="M16.844,130.733h0l-1.184-7.7a.563.563,0,0,0-.518-.518H13.682l.529-2.03a.665.665,0,0,0-.341-.791l-6.881-2.666a.448.448,0,0,0-.405.041.618.618,0,0,0-.26.376l-1.29,5.07H1.711a.563.563,0,0,0-.518.518l-1.184,7.7a.728.728,0,0,0,.113.52.5.5,0,0,0,.4.228h15.8a.5.5,0,0,0,.4-.228A.728.728,0,0,0,16.844,130.733Zm-9.67-12.3,5.875,2.276-1.473,5.658H5.156ZM1.16,130.215l.989-6.432H4.712l-.657,2.583H3.469a.644.644,0,0,0,0,1.266h9.915a.644.644,0,0,0,0-1.266h-.7l.672-2.583H14.7l.989,6.432Z"
      transform="translate(0 -117)"
      fill={color}
    />
  </svg>
);
export const DownloadSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.702"
    height="15.867"
    viewBox="0 0 16.702 15.867"
  >
    <g
      id="Groupe_1402"
      data-name="Groupe 1402"
      transform="translate(-42.667 -42.667)"
    >
      <path
        id="Tracé_343"
        data-name="Tracé 343"
        d="M58.534,405.333H43.5a.835.835,0,0,0,0,1.67H58.534a.835.835,0,0,0,0-1.67Z"
        transform="translate(0 -348.469)"
        fill="#fff"
      />
      <path
        id="Tracé_344"
        data-name="Tracé 344"
        d="M132.927,42.667a.835.835,0,0,0-.835.835v8.844l-2.748-2.756a.839.839,0,0,0-1.186,1.186l4.176,4.176a.835.835,0,0,0,1.181,0l0,0,4.176-4.176a.839.839,0,0,0-1.186-1.186l-2.747,2.756V43.5a.835.835,0,0,0-.835-.835Z"
        transform="translate(-81.908)"
        fill="#fff"
      />
    </g>
  </svg>
);

export const PdfSVG = () => (
  <svg
    id="Groupe_1138"
    data-name="Groupe 1138"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <g id="drive-pdf">
      <path
        id="Tracé_263"
        data-name="Tracé 263"
        d="M14.756,9.956h0a6.6,6.6,0,0,0,.356-1.6V8a2.776,2.776,0,0,0,0-1.778V6.044l-.178-.178h0a.174.174,0,0,1-.178.178,7.231,7.231,0,0,0,0,3.911ZM9.422,22.222a3.886,3.886,0,0,0-.889.533A8.759,8.759,0,0,0,6.222,25.6h0c1.067-.178,2.133-1.244,3.2-3.378Zm16.356-2.667c-.178-.178-.889-.711-3.378-.711h-.356v.178a9.542,9.542,0,0,0,3.378.889h.533v-.178h0A.174.174,0,0,1,25.778,19.556ZM28.444,0H3.556A3.566,3.566,0,0,0,0,3.556V28.444A3.566,3.566,0,0,0,3.556,32H28.444A3.566,3.566,0,0,0,32,28.444V3.556A3.566,3.566,0,0,0,28.444,0ZM26.489,20.978a3.55,3.55,0,0,1-1.6.356,12.815,12.815,0,0,1-5.333-1.244,29.272,29.272,0,0,0-7.111,1.422c-.178,0-.178,0-.356.178C9.956,25.422,8.178,27.2,6.756,27.2c-.356,0-.533,0-.711-.178l-.889-.533v-.178a1.6,1.6,0,0,1-.178-.889,6.842,6.842,0,0,1,3.378-3.733c.356-.178.889-.533,1.6-.889.533-.889,1.067-1.956,1.778-3.2a37.612,37.612,0,0,0,1.956-5.156h0c-.711-2.133-1.067-3.378-.356-5.867.178-.711.711-1.422,1.422-1.422h.356a2.527,2.527,0,0,1,1.067.356c1.245,1.244.711,4.089,0,6.4v.178a12.84,12.84,0,0,0,2.844,4.622,12.476,12.476,0,0,0,1.6,1.067c.889,0,1.6-.178,2.311-.178,2.133,0,3.556.356,4.089,1.244a2.254,2.254,0,0,1,.178,1.067A10.89,10.89,0,0,1,26.489,20.978ZM14.933,14.044a37.023,37.023,0,0,1-1.778,4.267c-.356.711-.711,1.244-1.067,1.956h.356a26.894,26.894,0,0,1,5.867-1.6,2.453,2.453,0,0,1-.711-.533A18.074,18.074,0,0,1,14.933,14.044Z"
        fill="#2c659d"
      />
    </g>
  </svg>
);

export const SearchSVG = ({ color, width, height }) => (
  <svg
    id="vuesax_linear_search-normal"
    data-name="vuesax/linear/search-normal"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
  >
    <g
      id="vuesax_linear_search-normal-2"
      data-name="vuesax/linear/search-normal"
    >
      <g id="search-normal">
        <path
          id="Vector"
          d="M19,9.5A9.5,9.5,0,1,1,9.5,0,9.5,9.5,0,0,1,19,9.5Z"
          transform="translate(2 2)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M2,2,0,0"
          transform="translate(20 20)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M0,0H24V24H0Z"
          fill="none"
          opacity="0"
        />
      </g>
    </g>
  </svg>
);

export const NotificationSVG = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.977"
    height="19.594"
    viewBox="0 0 16.977 19.594"
  >
    <path
      id="Notifications_icon_copy"
      dataname="Notifications icon copy"
      d="M1719.692,71.954a.731.731,0,0,1,.1-1.043l0,0a.778.778,0,0,1,1.076.1,2.04,2.04,0,0,0,.417.4,2.009,2.009,0,0,0,2.773-.4h.009a.779.779,0,0,1,1.077-.1.73.73,0,0,1,.105,1.041l-.005.006a3.531,3.531,0,0,1-4.884.658,3.463,3.463,0,0,1-.671-.658Zm-1.639-2.724a4.862,4.862,0,0,1-3.066-1.507,3.949,3.949,0,0,1-.967-2.683v-.119a4.07,4.07,0,0,1,.727-2.057l.072-.089a3.732,3.732,0,0,0,.886-1.724V59.273a.769.769,0,0,1,.994-.416.752.752,0,0,1,.468.57V61.2a.411.411,0,0,1,0,.1,5.119,5.119,0,0,1-1.223,2.4,2.446,2.446,0,0,0-.389,1.225v.19a2.492,2.492,0,0,0,.584,1.68,3.34,3.34,0,0,0,2.073.966,40.765,40.765,0,0,0,8.576,0,3.346,3.346,0,0,0,2.117-1.009,2.462,2.462,0,0,0,.558-1.629v-.2a2.538,2.538,0,0,0-.38-1.232,5.132,5.132,0,0,1-1.3-2.4.549.549,0,0,1,0-.1V59.411c-.257-2.6-2.924-4.213-5.245-4.213a5.838,5.838,0,0,0-2.817.715.781.781,0,0,1-.776,0,.731.731,0,0,1-.279-1.007.748.748,0,0,1,.316-.293,7.337,7.337,0,0,1,3.49-.905c3.065,0,6.5,2.12,6.89,5.526v1.776a3.65,3.65,0,0,0,.886,1.724.74.74,0,0,1,.071.1,3.921,3.921,0,0,1,.676,2.006l-.027.18a4.007,4.007,0,0,1-.94,2.7,4.915,4.915,0,0,1-3.118,1.509,41.505,41.505,0,0,1-8.858,0Z"
      transform="translate(-1714.02 -53.713)"
      fill={color}
    />
  </svg>
);

export const ContactSVG = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 21 21"
  >
    <g id="CS" transform="translate(-32 -32)">
      <path
        id="Tracé_345"
        data-name="Tracé 345"
        d="M50.75,41v-.75a8.25,8.25,0,1,0-16.5,0V41A2.252,2.252,0,0,0,32,43.25V47a2.252,2.252,0,0,0,2.25,2.25h3A.75.75,0,0,0,38,48.5V41.75a.75.75,0,0,0-.75-.75h-1.5v-.75a6.75,6.75,0,0,1,13.5,0V41h-1.5a.75.75,0,0,0-.75.75V48.5a.75.75,0,0,0,.75.75h1.5a.75.75,0,0,1-.75.75H45.362a2.246,2.246,0,0,0-2.112-1.5h-1.5a2.25,2.25,0,1,0,0,4.5h1.5a2.246,2.246,0,0,0,2.112-1.5H48.5a2.252,2.252,0,0,0,2.25-2.25A2.252,2.252,0,0,0,53,47V43.25A2.252,2.252,0,0,0,50.75,41ZM36.5,47.75H34.25A.75.75,0,0,1,33.5,47V43.25a.75.75,0,0,1,.75-.75H36.5Zm6.75,3.75h-1.5a.75.75,0,1,1,0-1.5h1.5a.75.75,0,1,1,0,1.5ZM51.5,47a.75.75,0,0,1-.75.75H48.5V42.5h2.25a.75.75,0,0,1,.75.75Z"
        fill={color}
      />
    </g>
  </svg>
);

export const PhoneSVG = ({
  color = "",
  width = "15.199",
  height = "15.257",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 15.199 15.257"
  >
    <g
      id="Groupe_1432"
      data-name="Groupe 1432"
      transform="translate(-0.912 -391.166)"
    >
      <path
        id="Tracé_346"
        data-name="Tracé 346"
        d="M3.992,401.308a16.187,16.187,0,0,0,5.384,4.217,8.38,8.38,0,0,0,3.013.892c.073,0,.142.006.215.006a2.494,2.494,0,0,0,1.935-.831.069.069,0,0,0,.013-.016,8.159,8.159,0,0,1,.61-.632c.149-.142.3-.291.446-.442a1.526,1.526,0,0,0-.006-2.273l-1.9-1.9a1.538,1.538,0,0,0-1.113-.512,1.59,1.59,0,0,0-1.125.509l-1.132,1.132c-.1-.06-.212-.114-.313-.164a3.936,3.936,0,0,1-.348-.19A11.977,11.977,0,0,1,6.809,398.5a6.9,6.9,0,0,1-.967-1.543c.3-.269.575-.55.844-.825.095-.1.193-.2.291-.294a1.5,1.5,0,0,0,0-2.276l-.942-.942c-.111-.111-.215-.218-.322-.329-.209-.215-.427-.436-.642-.635a1.576,1.576,0,0,0-1.113-.487,1.623,1.623,0,0,0-1.125.49l-1.182,1.182a2.429,2.429,0,0,0-.724,1.555,5.85,5.85,0,0,0,.439,2.529A14.714,14.714,0,0,0,3.992,401.308ZM1.7,394.46a1.673,1.673,0,0,1,.5-1.075l1.176-1.176a.851.851,0,0,1,.582-.269.808.808,0,0,1,.569.275c.212.2.411.4.626.62.108.111.218.221.329.335l.942.942a.737.737,0,0,1,0,1.182c-.1.1-.2.2-.294.3-.294.3-.569.578-.873.847l-.016.016a.618.618,0,0,0-.158.7c0,.01.006.016.009.025a7.443,7.443,0,0,0,1.11,1.805,12.641,12.641,0,0,0,3.048,2.776,4.541,4.541,0,0,0,.417.228,3.945,3.945,0,0,1,.348.19l.035.019a.661.661,0,0,0,.307.079.671.671,0,0,0,.471-.215l1.182-1.182a.837.837,0,0,1,.579-.281.794.794,0,0,1,.559.281l1.906,1.9a.763.763,0,0,1-.01,1.192c-.133.142-.272.278-.42.42a9.109,9.109,0,0,0-.661.686,1.736,1.736,0,0,1-1.356.575c-.054,0-.111,0-.164-.006a7.613,7.613,0,0,1-2.725-.816,15.379,15.379,0,0,1-5.125-4.015,14.085,14.085,0,0,1-2.5-4.157A5.1,5.1,0,0,1,1.7,394.46Z"
        transform="translate(0 0)"
        fill={color}
      />
    </g>
  </svg>
);

export const MailSVG = ({
  color = "#fff",
  width = "18.333",
  height = "12.89",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 18.333 12.89"
  >
    <g
      id="Groupe_1434"
      data-name="Groupe 1434"
      transform="translate(-54 -335.656)"
    >
      <g
        id="Groupe_1433"
        data-name="Groupe 1433"
        transform="translate(54 335.656)"
      >
        <path
          id="Tracé_347"
          data-name="Tracé 347"
          d="M16.722,391H1.611A1.614,1.614,0,0,0,0,392.611v9.668a1.614,1.614,0,0,0,1.611,1.611h15.11a1.614,1.614,0,0,0,1.611-1.611v-9.668A1.614,1.614,0,0,0,16.722,391Zm-.247,1.074-6.891,6.914a.627.627,0,0,1-.832,0l-6.891-6.914Zm-15.4,10.008v-9.273L5.7,397.445Zm.785.734,4.6-4.61,1.535,1.541a1.7,1.7,0,0,0,2.354,0l1.536-1.541,4.595,4.61Zm15.4-.734-4.621-4.637,4.621-4.637Z"
          transform="translate(0 -391)"
          fill={color}
        />
      </g>
    </g>
  </svg>
);
