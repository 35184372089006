import React from "react";

import { MailSVG, PhoneSVG } from "../Components/Svgs";

import "../ViewsStyles/ContactView.css";
function ContactView() {
  return (
    <div className="contactView d-flex flex-column justify-content-end h85">
      <button className="contactBtn d-flex align-items-center bg-blue px-3 py-2 br5 border-none mb-3">
        <PhoneSVG color="white" width="41px" height="41px" />
        <div className="ps-3">
          <p className="mb-0 f13 medium text-start text-white">
            N'hésitez pas à nous contacter
          </p>
          <p className="mb-0 f25 medium text-start text-white">
            04 93 88 84 14
          </p>
        </div>
      </button>
      <button className="contactBtn d-flex align-items-center bg-yellow px-3 py-2 br5 border-none mb-4">
        <MailSVG color="white" width="41px" height="41px" />
        <p className="mb-0 f16 medium text-white ps-3">ÉCRIVEZ-NOUS</p>
      </button>
    </div>
  );
}

export default ContactView;
