import comptable_1 from "../Images/Images/comptables/cecilia_g.jpg";
import comptable_2 from "../Images/Images/comptables/drina.jpg";
import comptable_3 from "../Images/Images/comptables/christine.jpg";

export const comptablesData = [
  {
    id: 1,
    image: comptable_1,
    name: "Cécilia GITENET",
    email: "intgm@mail.fr",
  },
  { id: 2, image: comptable_2, name: "Alexandrina", email: "intgm@mail.fr" },
  { id: 3, image: comptable_3, name: "Christine", email: "intgm@mail.fr" },
];
