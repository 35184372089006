import React from "react";

import { DetailsSvg } from "../../Svgs";
import LinksPopup from "../LinksPopup/LinksPopup";

import "./MoreDetailsButton.css";

function MoreDetailsButton() {
  const [showLinksPopup, setShowLinksPopup] = React.useState(false);

  return (
    <button
      type="button"
      className="unset moreDetailsBtn position-relative d-none d-lg-flex align-items-center"
      onClick={() => setShowLinksPopup(!showLinksPopup)}
    >
      <DetailsSvg color={showLinksPopup ? "#2c659d" : "black"} />
      {showLinksPopup ? <LinksPopup /> : null}
    </button>
  );
}

export default MoreDetailsButton;
