import React from "react";

import CustomInput from "../../../Common/CustomInput/CustomInput";
import SelectPaiementWrapper from "../../../Common/SelectPaiement/SelectPaiementWrapper";
import CustomButton from "../../../Common/CustomButton/CustomButton";

import useResponsive from "../../../../hooks/useResponsive";

function PaiementSection() {
  const isMobileSize = useResponsive();
  return (
    <form className="w70 wm100">
      <h2 className="f20 sBold text-blue ps-lg-2 mb-4">
        Informations de paiement
      </h2>
      <div className="d-flex justify-content-between mb-3">
        <CustomInput label="Numéro" width={isMobileSize ? "22%" : "10%"} />
        <CustomInput label="Rue" width={isMobileSize ? "74%" : "88%"} />
      </div>
      <div className="d-flex justify-content-between mb-3">
        <CustomInput
          label="Code postale"
          width={isMobileSize ? "40%" : "49%"}
        />
        <CustomInput label="Ville" width={isMobileSize ? "56%" : "49%"} />
      </div>
      <CustomInput label="IBAN" className="mb-3" />
      <CustomInput label="BIC ou Swift" className="mb-3" />
      <CustomInput label="Nom du propriétaire" className="mb-4" />
      <h2 className="f20 fm18 sBold text-blue ps-2 mb-4">
        Choisissez votre mode de paiement
      </h2>
      <SelectPaiementWrapper />

      <CustomButton
        variant="blue"
        text="Enregistrer"
        className="my-4 mx-auto"
        width="151px"
      />
    </form>
  );
}

export default PaiementSection;
