import axios from "axios";
import { useState, useEffect } from "react";

const useFetchSignalements = (ID_PERSO) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let qualites = await axios.get(
          `https://taboni-soft-api.labo-linkibe.fr/api/qualites?filters[ID_PERSO][$eq]=${ID_PERSO}`
        );
        qualites = qualites.data?.data;
        let immeubles = [];
        if (qualites.length) {
          for (let qualite of qualites) {
            let ImmeubleCode = qualite.attributes.IIII;
            let immeuble = await axios.get(
              `https://taboni-soft-api.labo-linkibe.fr/api/immeubles?filters[IIII][$eq]=${ImmeubleCode}`
            );
            immeuble = immeuble.data.data[0];
            immeubles.push({
              IIII: immeuble.attributes.IIII,
            });
          }
        }
        if (immeubles) {
          for (const immeuble of immeubles) {
            let evenement = await axios.get(
              `https://taboni-soft-api.labo-linkibe.fr/api/evenements?filters[iiii][$eq]=${immeuble.IIII}&fields[0]=no&fields[1]=date&fields[2]=noperso&fields[3]=type&fields[4]=obj1&fields[5]=obj2&fields[6]=obj3&fields[7]=obj4&fields[8]=traite`
            );
            evenement = evenement.data.data;
            setData((prevState) => {
              return [...prevState, ...evenement];
            });
          }
        }
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [ID_PERSO]);

  return { data, loading, error };
};

export default useFetchSignalements;
