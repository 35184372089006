import React, { useState } from "react";
import { Dialog } from "primereact/dialog";

import SignalerButton from "./SignalerButton/SignalerButton";
import SignalementNew from "../../Pages/Signalements/SignalementNew/SignalementNew";

import "./SignalerModal.css";

function SignalerModal({ buttonText, circular }) {
  const [show, setShow] = useState(false);

  const onHide = () => {
    setShow(false);
  };
  return (
    <div className="signalerModal">
      <SignalerButton
        setShow={setShow}
        buttonText={buttonText}
        circular={circular}
      />
      <Dialog visible={show} modal onHide={onHide} className="signalerDialog">
        <SignalementNew onHide={onHide} />
      </Dialog>
    </div>
  );
}

export default SignalerModal;
