import React, { useState } from "react";
import ExtraitSection from "../ExtraitSection/ExtraitSection";
import ViewWrapper from "../../../Common/ViewWrapper/ViewWrapper";
import useFetchExtraits from "../../../../hooks/useFetchExtraits";

function ExtraitsContainer() {
  const [activeIndex, setActiveIndex] = useState(null);
  const { data, loading, error } = useFetchExtraits();
  return (
    <ViewWrapper loading={loading} error={error}>
      <div>
        {data.map((immeuble, index) =>
          immeuble.NOM_IMM ? (
            <ExtraitSection
              key={immeuble.id}
              immeuble={immeuble}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              index={index}
            />
          ) : null
        )}
      </div>
    </ViewWrapper>
  );
}

export default ExtraitsContainer;
