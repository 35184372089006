import React from "react";
import FolderCard from "../../../Common/FolderCard/FolderCard";

function FoldersContainer({ foldersData }) {
  return (
    <div className="cardsContainer d-flex flex-lg-wrap">
      {foldersData.map(({ id, title, filesCount }) => {
        return <FolderCard key={id} title={title} filesCount={filesCount} />;
      })}
    </div>
  );
}

export default FoldersContainer;
