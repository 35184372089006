/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Assets
import { PolygoneSVG } from "../../Svgs";
// style
import "./informationCard.css";
/* -------------------------------------------------------------------------- */
/*                                  component                                 */
/* -------------------------------------------------------------------------- */
function InformationCard() {
  /* ******************************** Rendering ******************************* */
  return (
    <div className="InformationCard w-100 bg-lightGray position-relative br5 mb-4">
      <div className="information-card-content-container position-relative zIndex-1 ">
        <div className="informarion-card-polygone-container zIndex-n-1 position-absolute">
          <PolygoneSVG />
        </div>
        <div className="position-relative zIndex-1">
          <h3 className="f17 sBold text-blue m-0 text-uppercase">
            cabinet taboni
          </h3>
          <p className="f14 text-dark regular w80 text-uppercase">
            82 bd gambetta 06000 nice
          </p>
        </div>
        <div className="position-relative zIndex-1">
          <h3 className="f17 sBold text-blue  m-0 text-uppercase">téléphone</h3>
          <p className="f14 text-dark regular w80 text-uppercase">
            04 93 88 84 14{" "}
          </p>
        </div>
        <div className="position-relative zIndex-1">
          <h3 className="f17 sBold text-blue  m-0 text-uppercase">
            horaires d'ouverture
          </h3>
          <p className="f14 text-dark regular w80 m-0">
            Lundi au jeudi de 9h à 12 et 14h à 18h et
          </p>
          <p className="f14 text-dark regular w80">
            Vendredi de 9h à 12h et de 14h à 17h
          </p>
        </div>
      </div>
    </div>
  );
}

export default InformationCard;
