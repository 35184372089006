import React from "react";
import "./DocumentsDashboard.css";
import Polygone from "../../../../../Images/Images/dashboard/Polygonewhite.png";
import DocumentDetail from "../../../../Common/DocumentDetail/DocumentDetail";
import { Link } from "react-router-dom";
const DocumentsDashboard = () => {
  return (
    <div className="documentsDashboard w43 br5 bShadow pt-4 px-4 pb-4 position-relative bg-lightGray f25 d-none d-lg-block">
      <div className="position-absolute zIndex-0">
        <img src={Polygone} alt="polygone" />
      </div>
      <h3 className="text-blue sBold f25 mb-4 pt-2 position-relative zIndex-1">
        Derniers documents
      </h3>
      <div className="d-flex flex-column">
        <DocumentDetail title="Décompte du 31-10-2021" forDashboard />
        <DocumentDetail title="Décompte du 31-10-2021" forDashboard />
        <DocumentDetail title="Décompte du 31-10-2021" forDashboard />
        <DocumentDetail title="Décompte du 31-10-2021" forDashboard />
        <hr />
        <Link to="/documents" className="text-blue f15 medium">
          Voir tous les documents
        </Link>
      </div>
    </div>
  );
};

export default DocumentsDashboard;
