export const documentsData = [
  {
    id: 1,
    title: "Décompte du 31-10-2021",
    description: "Décompte de charge",
    date: "31-10-2021",
  },
  {
    id: 2,
    title: "Appel du 01-02-2022",
    description: "Décompte de charge",
    date: "31-10-2021",
  },
  {
    id: 3,
    title: "Décompte du 31-10-2021",
    description: "Décompte de charge",
    date: "31-10-2021",
  },
  {
    id: 4,
    title: "Décompte du 31-10-2021",
    description: "Décompte de charge",
    date: "31-10-2021",
  },
];
