const initialState = {
  qualites: null,
};

export const qualitesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_QUALITES":
      return { ...state, qualites: action.payload };

    default:
      return state;
  }
};
